import { MockDataOverrideDecorator } from '@attentive/mock-data';

import Query, {
  CustomAttributeUsage_TargetingCommon_Query as QueryType,
} from '../../components/CustomAttributeDialog/components/CustomAttributeUsage/__generated__/CustomAttributeUsage_TargetingCommon_Query.graphql';

export type CustomAttributeUsageArgs = {
  hasAttributeUsages?: boolean;
};

export const CustomAttributeUsageMockDecorator = MockDataOverrideDecorator<QueryType>(
  Query,
  ({ variables }, { args }: { args: CustomAttributeUsageArgs }) => {
    return {
      customAttribute: {
        __typename: 'CustomPropertyDefinition',
        id: variables.id,
        usages: args.hasAttributeUsages
          ? [
              {
                id: 'segment-1',
                internalId: 20,
                name: 'First segment usage',
                segmentCount: {
                  sms: {
                    activeSubscribers: 593,
                  },
                  email: {
                    activeSubscribers: 3000,
                  },
                  smsAndEmail: {
                    activeSubscribers: 2331,
                  },
                },
              },
              {
                id: 'segment-2',
                internalId: 21,
                name: 'Second segment usage',
                segmentCount: {
                  sms: {
                    activeSubscribers: 1232,
                  },
                  email: {
                    activeSubscribers: 5050,
                  },
                  smsAndEmail: {
                    activeSubscribers: 2921,
                  },
                },
              },
              {
                id: 'segment-3',
                internalId: 22,
                name: 'Third segment usage',
                segmentCount: {
                  sms: {
                    activeSubscribers: 1039,
                  },
                  email: {
                    activeSubscribers: 3230,
                  },
                  smsAndEmail: {
                    activeSubscribers: 2021,
                  },
                },
              },
            ]
          : [],
      },
    };
  }
);
