import React from 'react';

import { Box, Button, Icon, PicnicCss, StandardDialog } from '@attentive/picnic';

interface ViewAllDialogProps {
  css?: PicnicCss;
  header: string;
  children: React.ReactNode;
}

export const ViewAllDialog: React.FC<ViewAllDialogProps> = ({ css, header, children }) => {
  return (
    <Box as="span" css={css}>
      <StandardDialog>
        <StandardDialog.Trigger>
          <Button css={{ fontWeight: 'normal', minHeight: 'auto' }} variant="subdued">
            View all <Icon name="NewWindow" size="small" />
          </Button>
        </StandardDialog.Trigger>
        <StandardDialog.Content css={{ width: '50vw' }}>
          <StandardDialog.Header>
            <StandardDialog.Heading>{header}</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body>{children}</StandardDialog.Body>
        </StandardDialog.Content>
      </StandardDialog>
    </Box>
  );
};
