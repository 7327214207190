import { SegmentParameters } from '../../constants';
import { usesUtcDate } from '../SegmentCreateEditModal/components/TimeSelector/utils';
import { getTimezoneDisplayDate } from '../SegmentCreateEditModal/utils/dateTime';

export function getDateReplacements(
  parameters: SegmentParameters,
  companyTimezone: string
): Record<string, string> {
  const isTimeCondition = parameters.time || parameters.startTime || parameters.endTime;
  // The segment descriptions are in UTC so we don't have to update time conditions that use UTC
  if (!isTimeCondition || usesUtcDate(parameters)) return {};

  return [parameters.time, parameters.startTime, parameters.endTime].reduce((acc, param) => {
    if (!param) return acc;

    const existingDate = getTimezoneDisplayDate(param, 'UTC');
    const replacementDate = getTimezoneDisplayDate(param, companyTimezone);

    return { ...acc, [existingDate]: replacementDate };
  }, {});
}
