import React from 'react';

import { Box, ContainedLabel, PicnicCss, Text } from '@attentive/picnic';

import { OperatorType } from '../../../constants';

type OperatorLabelProps = {
  css?: PicnicCss;
  operator: OperatorType;
};

export const OperatorLabel: React.FC<OperatorLabelProps> = ({ css, operator }) => {
  const isAndOperator = operator === OperatorType.AND;
  const variant = isAndOperator ? 'success' : 'decorative3';
  const label = isAndOperator ? 'All' : 'Any';

  return (
    <Box css={{ mb: '$space2', ...css }}>
      <ContainedLabel css={{ fontSize: '$fontSize2', minHeight: '$size6' }} variant={variant}>
        {label}
      </ContainedLabel>
      <Text as="span" css={{ fontWeight: '$bold' }}>
        {' '}
        of these conditions match a subscriber
      </Text>
    </Box>
  );
};
