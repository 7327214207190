import { getCurrentSegmentCount, getCurrentSegmentCountV2 } from './currentSegmentCount';
import { getKlaviyoIntegration, getIntegrations, getShopifyIntegration } from './integrations';
import { getKlaviyoCampaigns } from './klaviyoCampaigns';
import { getLocations } from './location';
import { postSegment } from './postSegment';
import { getProductAttributes } from './productAttributes';
import { getProfileSegmentOptions } from './profileSegmentOptions';
import { getSegment } from './segment';
import { getSegmentOptionAvailabilities } from './segmentOptionAvailabilities';
import { getSegmentOptions } from './segmentOptions';
import { getSegmentableAttributes } from './segmentableAttributes';

export const targetingHandlers = [
  getIntegrations,
  getKlaviyoIntegration,
  getShopifyIntegration,
  getLocations,
  getProductAttributes,
  getProfileSegmentOptions,
  getSegment,
  getSegmentableAttributes,
  getSegmentOptionAvailabilities,
  getSegmentOptions,
  getCurrentSegmentCount,
  getCurrentSegmentCountV2,
  postSegment,
  getKlaviyoCampaigns,
];
