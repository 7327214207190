import React, { VFC } from 'react';

import { PicnicCss, Text } from '@attentive/picnic';

import { IsVerbLabel, IsVerbType } from '../../../constants';
import { formatNumberWithCommas } from '../../../utils';
import { ViewAllDialog } from '../../ViewAllDialog';

const MAX_ITEMS_TO_DISPLAY = 10;

interface LocationValue {
  value: string;
}

interface OwnProps {
  css?: PicnicCss;
  description?: string;
  isVerb: IsVerbType;
  operator: string;
  values?: LocationValue[];
}

const BulkPostalCodeDescription: VFC<OwnProps> = ({
  css,
  description,
  isVerb,
  operator,
  values,
}) => {
  if (!description || !values) return null;

  const exceedsMaxItems = values.length > MAX_ITEMS_TO_DISPLAY;
  const formattedDescription = exceedsMaxItems ? `${description.replace('or', '')} ` : description;
  const extraItems = formatNumberWithCommas(values.length - MAX_ITEMS_TO_DISPLAY);

  return (
    <Text css={{ display: 'inline', ...css }}>
      {formattedDescription}
      {exceedsMaxItems && (
        <>
          <span>& {extraItems} more&nbsp;</span>
          <ViewAllDialog
            css={{ position: 'relative', top: '-2px' }}
            header="Condition description detail"
          >
            <Text>
              <strong>{operator.toLowerCase()}</strong> zipcode {IsVerbLabel[isVerb]}
            </Text>
            <Text>{values.map(({ value }) => value).join(', ')}</Text>
          </ViewAllDialog>
          &nbsp;
        </>
      )}
    </Text>
  );
};

export default BulkPostalCodeDescription;
