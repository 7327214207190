import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { NumberValueDisplayableOption } from '../../constants/interfaces';
import { mockLocationsResponse } from '../data/locations';

interface GetLocationsHeaders {
  getLocationsDelay?: number;
  getLocationsResponse?: NumberValueDisplayableOption[];
  getLocationsStatus?: number;
}

export const getLocations = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options/location',
  (req, res, ctx) => {
    const {
      getLocationsDelay = 0,
      getLocationsResponse = mockLocationsResponse,
      getLocationsStatus = 200,
    }: GetLocationsHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getLocationsDelay),
      ctx.status(getLocationsStatus),
      ctx.json(getLocationsResponse)
    );
  }
);
