import { MockDataOverrideDecorator, parseMSWHeaders } from '@attentive/mock-data';

import Query, {
  CustomAttributeList_TargetingCommon_Query as QueryType,
} from '../../components/CustomAttributeList/__generated__/CustomAttributeList_TargetingCommon_Query.graphql';
import RefetchQuery from '../../components/CustomAttributeList/__generated__/useCustomAttributes_TargetingCommon_Attributes_RefetchableFragment.graphql';
import { mockCustomAttributes } from '../data/customAttributes';

import { CustomAttributeHeaders } from './customAttributeMutations';

export type CustomAttributeListMockDecoratorArgs = {
  emptyResponse?: boolean;
};

export const CustomAttributeListMockDecorator = MockDataOverrideDecorator<QueryType>(
  Query,
  (_, { args }: { args: CustomAttributeListMockDecoratorArgs }) => {
    return {
      company: {
        userPropertyDefinitionsV2: {
          edges: args.emptyResponse ? [] : mockCustomAttributes,
        },
      },
    };
  }
);

// Currently there is no way to generate the raw response for a refetchable fragment
// As a work around, we can use the QueryType for the parent query that contains this fragment.
// The body of the response is the same in the refetch query as the original query so we can safely use this type here
export const RefetchCustomAttributesMockDecorator = MockDataOverrideDecorator<QueryType>(
  RefetchQuery,
  (req) => {
    const { deletedCustomAttributes = [] } = parseMSWHeaders<CustomAttributeHeaders>(req.headers);

    return {
      company: {
        userPropertyDefinitionsV2: {
          edges: mockCustomAttributes.filter(
            ({ node }) => !deletedCustomAttributes.includes(node.id)
          ),
        },
      },
    };
  }
);
