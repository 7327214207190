import { SegmentableAttributes } from '../../constants';

export const segmentableAttributesMock: {
  segmentableAttributesByVendor: SegmentableAttributes;
} = {
  segmentableAttributesByVendor: {
    KLAVIYO: [
      {
        displayName: 'First name',
        attributeName: '$first_name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: '���Account Name',
        attributeName: '���Account Name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        // Test an empty unicode character. This issue cropped up in a customer's integrations data
        displayName: '﻿',
        attributeName: '﻿',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: '﻿Account Name',
        attributeName: '﻿Account Name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Last name',
        attributeName: '$last_name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Zip',
        attributeName: '$zip',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Klaviyo attribute set',
        attributeName: 'klaviyoattributeSet',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'TimeStamp',
        attributeName: 'timeStamp',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Score',
        attributeName: 'score',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Sms consent',
        attributeName: 'sms_consent',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Opt-in date',
        attributeName: 'created',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Sms attentive signup',
        attributeName: 'sms_attentive_signup',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Phone number region',
        attributeName: '$phone_number_region',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Custom klaviyo timestamp',
        attributeName: 'baz',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Custom klaviyo integer',
        attributeName: 'bar',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Custom klaviyo text',
        attributeName: 'foo',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
    ],
    VENDOR_SEGMENT: [
      {
        displayName: 'Text attribute',
        attributeName: 'trait2',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Boolean attribute',
        attributeName: 'trait3',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Integer attribute',
        attributeName: 'trait1',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Timestamp attribute',
        attributeName: 'trait4',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Float attribute',
        attributeName: 'trait4.5',
        segmentDataType: 'SEGMENT_DATA_TYPE_FLOAT',
      },
      {
        displayName: 'Created at',
        attributeName: 'created_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Gender',
        attributeName: 'gender',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Age',
        attributeName: 'age',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
    ],
    VENDOR_YOTPO: [
      {
        displayName: 'Thirty party id',
        attributeName: 'thirty_party_id',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Pos account id',
        attributeName: 'pos_account_id',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Vip tier expiration',
        attributeName: 'vip_tier_expiration',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Vip tier entry date',
        attributeName: 'vip_tier_entry_date',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Vip tier maintenance requirements',
        attributeName: 'vip_tier_maintenance_requirements',
        segmentDataType: 'SEGMENT_DATA_TYPE_OBJECT',
      },
      {
        displayName: 'Vip tier name',
        attributeName: 'vip_tier_name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Birthday month',
        attributeName: 'birthday_month',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Birth day',
        attributeName: 'birth_day',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Birthday year',
        attributeName: 'birthday_year',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Credit balance',
        attributeName: 'credit_balance',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Email',
        attributeName: 'email',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Has store account',
        attributeName: 'has_store_account',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Last seen at',
        attributeName: 'last_seen_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Credit balance in customer currency',
        attributeName: 'credit_balance_in_customer_currency',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Opt in',
        attributeName: 'opt_in',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Points expire at',
        attributeName: 'points_expire_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Last purchase at',
        attributeName: 'last_purchase_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Phone number',
        attributeName: 'phone_number',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Opted in at',
        attributeName: 'opted_in_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Perks redeemed',
        attributeName: 'perks_redeemed',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Points balance',
        attributeName: 'points_balance',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Points earned',
        attributeName: 'points_earned',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Third party id',
        attributeName: 'third_party_id',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Total purchases',
        attributeName: 'total_purchases',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Total spend cents',
        attributeName: 'total_spend_cents',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Vip tier actions completed',
        attributeName: 'vip_tier_actions_completed',
        segmentDataType: 'SEGMENT_DATA_TYPE_OBJECT',
      },
      {
        displayName: 'Vip tier upgrade requirements',
        attributeName: 'vip_tier_upgrade_requirements',
        segmentDataType: 'SEGMENT_DATA_TYPE_OBJECT',
      },
      {
        displayName: 'First name',
        attributeName: 'first_name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Last name',
        attributeName: 'last_name',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Custom Yotpo text',
        attributeName: 'custom_yotpo',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
    ],
    SHOPIFY: [
      {
        displayName: 'Accepts Marketing',
        attributeName: 'ACCEPTS_MARKETING',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Currency',
        attributeName: 'CURRENCY',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Customer account status',
        attributeName: 'CUSTOMER_STATE',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Tag',
        attributeName: 'CUSTOMER_TAG',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Opt-in level',
        attributeName: 'MARKETING_OPT_IN_LEVEL',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Lifetime Spend',
        attributeName: 'LIFETIME_SPEND',
        segmentDataType: 'SEGMENT_DATA_TYPE_FLOAT',
      },
      {
        displayName: 'Number of orders',
        attributeName: 'ORDERS_COUNT',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Opt-in date',
        attributeName: 'ACCEPTS_MARKETING_UPDATED_TIME',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Date added',
        attributeName: 'CREATED_TIME',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Updated Time',
        attributeName: 'UPDATED_TIME',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
    ],
    VENDOR_PUNCHH: [
      {
        displayName: 'Signup channel',
        attributeName: 'signup_channel',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Sms subscription',
        attributeName: 'sms_subscription',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Unsubscribed',
        attributeName: 'unsubscribed',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Zip code',
        attributeName: 'zip_code',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'State',
        attributeName: 'state',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Age verified',
        attributeName: 'age_verified',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Banned',
        attributeName: 'banned',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Checkins',
        attributeName: 'checkins',
        segmentDataType: 'SEGMENT_DATA_TYPE_LIST',
      },
      {
        displayName: 'Gender',
        attributeName: 'gender',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Preferred locale',
        attributeName: 'preferred_locale',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Anniversary',
        attributeName: 'anniversary',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'City',
        attributeName: 'city',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Birthday',
        attributeName: 'birthday',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Favorite locations',
        attributeName: 'favorite_locations',
        segmentDataType: 'SEGMENT_DATA_TYPE_LIST',
      },
      {
        displayName: 'Last activity at',
        attributeName: 'last_activity_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Last sign in at',
        attributeName: 'last_sign_in_at',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Marketing email subscription',
        attributeName: 'marketing_email_subscription',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Marketing pn subscription',
        attributeName: 'marketing_pn_subscription',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
      {
        displayName: 'Custom punchh text',
        attributeName: 'custom_punchh_text',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Custom punchh timestamp',
        attributeName: 'custom_punchh_timestamp',
        segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
      },
      {
        displayName: 'Custom punchh float',
        attributeName: 'custom_punchh_float',
        segmentDataType: 'SEGMENT_DATA_TYPE_FLOAT',
      },
      {
        displayName: 'Custom punchh integer',
        attributeName: 'custom_punchh_integer',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Custom punchh boolean',
        attributeName: 'custom_punchh_boolean',
        segmentDataType: 'SEGMENT_DATA_TYPE_BOOLEAN',
      },
    ],
    VENDOR_RECHARGE: [
      {
        displayName: 'Number subscriptions',
        attributeName: 'number_subscriptions',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Subscriptions',
        attributeName: 'subscriptions',
        segmentDataType: 'SEGMENT_DATA_TYPE_LIST',
        nestedOptions: [
          {
            attributeName: 'status',
            displayName: 'Subscription status',
            segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
            values: ['ACTIVE', 'CANCELLED', 'EXPIRED'],
          },
          {
            attributeName: 'created_at',
            displayName: 'Subscription activation date',
            segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
          },
          {
            attributeName: 'cancelled_at',
            displayName: 'Subscription cancellation date',
            segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
          },
          {
            attributeName: 'next_charge_scheduled_at',
            displayName: 'Subscription upcoming charge date',
            segmentDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
          },
          {
            attributeName: 'cancellation_reason',
            displayName: 'Churn reason',
            segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
            values: [
              'This is too expensive',
              'This was created by accident',
              'I already have more than I need',
              'I need it sooner',
              'I no longer use this product',
              'I want a different product or variety',
              'Other reason',
            ],
          },
        ],
      },
      {
        displayName: 'Billing phone',
        attributeName: 'billing_phone',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Phone',
        attributeName: 'phone',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Billing country',
        attributeName: 'billing_country',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Id',
        attributeName: 'id',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Number active subscriptions',
        attributeName: 'number_active_subscriptions',
        segmentDataType: 'SEGMENT_DATA_TYPE_INTEGER',
      },
      {
        displayName: 'Hash',
        attributeName: 'hash',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
      {
        displayName: 'Email',
        attributeName: 'email',
        segmentDataType: 'SEGMENT_DATA_TYPE_TEXT',
      },
    ],
  },
};
