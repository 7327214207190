import { MockDataOverrideDecorator, parseMSWHeaders, storeMSWHeaders } from '@attentive/mock-data';

import UpdateAttributeMutation, {
  UpdateCustomAttributeTargetingCommonMutation as UpdateAttributeMutationType,
} from '../../components/CustomAttributeDialog/components/ExistingCustomAttributeDialog/__generated__/UpdateCustomAttributeTargetingCommonMutation.graphql';
import CreateMutation, {
  CreateCustomAttributeTargetingCommonMutation as CreateMutationType,
} from '../../components/CustomAttributeDialog/components/NewCustomAttributeDialog/__generated__/CreateCustomAttributeTargetingCommonMutation.graphql';
import DeleteMutation, {
  DeleteCustomAttributeTargetingCommonMutation as DeleteMutationType,
} from '../../components/CustomAttributeList/__generated__/DeleteCustomAttributeTargetingCommonMutation.graphql';
import { mockCustomAttributes } from '../data/customAttributes';

export type CustomAttributeHeaders = {
  deletedCustomAttributes?: string[];
};

export type CustomAttributeMutationArgs = {
  throwError?: boolean;
};

export const CreateCustomAttributeMockDecorator = MockDataOverrideDecorator<CreateMutationType>(
  CreateMutation,
  ({ variables }, { args }: { args: CustomAttributeMutationArgs }) => {
    if (args.throwError) throw new Error('Error!');

    return {
      createCustomPropertyDefinition: {
        customPropertyDefinition: {
          id: 'CustomPropertyDefinition-new-item',
          name: variables.newFields.name,
          customPropertyId: { internalId: 'CustomPropertyID-new-item' },
        },
      },
    };
  }
);

export const DeleteCustomAttributeMockDecorator = MockDataOverrideDecorator<DeleteMutationType>(
  DeleteMutation,
  ({ headers, variables }, { args }: { args: CustomAttributeMutationArgs }) => {
    if (args.throwError) throw new Error('Error!');

    const { customPropertyId } = variables;
    const { deletedCustomAttributes = [] } = parseMSWHeaders<CustomAttributeHeaders>(headers);

    const attribute = mockCustomAttributes.find(({ node }) => node.id === customPropertyId);

    if (!attribute) throw new Error('Attribute not found');

    storeMSWHeaders({
      deletedCustomAttributes: [...deletedCustomAttributes, customPropertyId],
    });

    return {
      deleteCustomPropertyDefinition: {
        customPropertyDefinition: {
          id: attribute.node.id,
          name: attribute.node.name,
          __typename: 'CustomPropertyDefinition',
        },
      },
    };
  }
);

export const UpdateCustomAttributeMockDecorator =
  MockDataOverrideDecorator<UpdateAttributeMutationType>(
    UpdateAttributeMutation,
    ({ variables }, { args }: { args: CustomAttributeMutationArgs }) => {
      if (args.throwError) {
        throw new Error('Error!');
      }

      const { customPropertyId, newFields } = variables;

      return {
        updateCustomPropertyDefinition: {
          customPropertyDefinition: {
            id: customPropertyId,
            name: newFields.name,
            customPropertyId: {
              internalId: 'InternalCustomPropertyId',
            },
            propertyType: newFields.propertyType,
            typeDefinition:
              newFields.propertyType === 'TYPE_ENUM'
                ? {
                    __typename: 'EnumeratedPropertyDefinition',
                    enumeratedValues: newFields.enumValues?.map((value, idx) => ({
                      isDeleted: false,
                      value,
                      valueId: { id: `EnumeratedPropertyValueID-${idx}` },
                    })),
                  }
                : null,
          },
        },
      };
    }
  );
