import React, { FC, ReactNode } from 'react';

import { CompanyFeatureFlags } from '@attentive/acore-utils';
import { Box, Button, StandardDialog } from '@attentive/picnic';

import { useNewFeatureAnnouncement } from './hooks';

const PU_MAX_FEATURE_ANNOUNCE_DIALOG_WIDTH = '750px';

interface OwnProps {
  headerText: string;
  buttonText: string;
  children: ReactNode;
  featureFlag: keyof CompanyFeatureFlags;
  expirationDate?: Date;
  onClick?: () => void;
}

export const FeatureAnnouncementDialog: FC<OwnProps> = ({
  headerText,
  buttonText,
  children,
  featureFlag,
  expirationDate,
  onClick,
}) => {
  const { isOpen, dismiss } = useNewFeatureAnnouncement(featureFlag, expirationDate);

  const onOpenChange = (openVal: boolean) => {
    if (!openVal) {
      dismiss();
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    dismiss();
  };

  return isOpen ? (
    <StandardDialog open onOpenChange={onOpenChange}>
      <StandardDialog.Content css={{ width: PU_MAX_FEATURE_ANNOUNCE_DIALOG_WIDTH }}>
        <StandardDialog.Header>
          <StandardDialog.Heading>{headerText}</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>{children}</StandardDialog.Body>
        <StandardDialog.Footer>
          <Box css={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button variant="primary" onClick={handleClick}>
              {buttonText}
            </Button>
          </Box>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  ) : null;
};
