import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  UserPropertyType,
  HasVerbType,
  IsVerbType,
  OperatorComparator,
  OperatorType,
  Segment,
  SegmentComponentType,
  SiteCondition,
  Source,
  SyncStatus,
  TimeComparatorType,
  DurationUnit,
  PropertiesFilter,
} from '../constants';

export const emptySegment: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [],
    },
  ],
  description: 'Blank Segment',
  id: 1234,
  name: 'My Blank Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const testSegNoDupes: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.URL_VIEWED,
          description: 'has visited a specific page http://foo.com',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.VISITED_SITE,
            propertyAttributeCondition: SiteCondition.SPECIFIC_PAGE,
            pattern: 'http://foo.com',
          },
        },
        {
          id: 4,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
      ],
    },
  ],
  description: 'Mock Mixed And / Or Segment',
  id: 1234,
  name: 'My And / Or Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const testSegDupeWithinExpression: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'custom property',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            attribute: '95',
            deleted: false,
            displayName: 'Favorite Color',
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            userPropertyValues: [
              {
                displayName: 'Blue',
                optionValue: '23',
                deleted: false,
              },
              {
                displayName: 'Red',
                optionValue: '24',
                deleted: false,
              },
            ],
          },
        },
        {
          id: 4,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
        {
          id: 3,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'custom property',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            attribute: '95',
            deleted: false,
            displayName: 'Favorite Color',
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            userPropertyValues: [
              {
                displayName: 'Red',
                optionValue: '24',
                deleted: false,
              },
              {
                displayName: 'Blue',
                optionValue: '23',
                deleted: false,
              },
            ],
          },
        },
      ],
    },
  ],
  description: 'Mock Mixed And / Or Segment',
  id: 1234,
  name: 'My And / Or Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const testSegDupeWholeExpressions: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.URL_VIEWED,
          description: 'has visited a specific page http://foo.com',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.VISITED_SITE,
            propertyAttributeCondition: SiteCondition.SPECIFIC_PAGE,
            pattern: 'http://foo.com',
          },
        },
        {
          id: 4,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
      ],
    },
    {
      id: 3,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 4,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
      ],
    },
  ],
  description: 'Mock Mixed And / Or Segment',
  id: 1234,
  name: 'My And / Or Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const segmentWithSeveralConditions: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            subscriberAction: ActionFilter.JOINED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            hasVerb: HasVerbType.HAS,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          description: 'has received a text in the last 30 days',
          syncing: false,
          parameters: {
            subscriberAction: ActionFilter.SENT,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            hasVerb: HasVerbType.HAS,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            timeComparator: TimeComparatorType.IN_THE_LAST,
            durationUnit: DurationUnit.DAYS,
            durationTime: 30,
          },
          type: SegmentComponentType.CUSTOM,
        },
        {
          id: 4,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
        {
          id: 5,
          description: 'has not subscriber to email',
          syncing: false,
          parameters: {
            subscriberAction: ActionFilter.JOINED,
            subscriberActionChannel: ActionChannel.EMAIL,
            subscriberActionSource: ActionSource.ATTENTIVE,
            hasVerb: HasVerbType.HAS_NOT,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
          type: SegmentComponentType.CUSTOM,
        },
      ],
    },
  ],
  description: 'now with more conditions!',
  id: 3456,
  name: 'My segment with 5 conditions!',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591488585,
  updated: 1591729525,
};

export const segmentDetailsMock: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
        {
          id: 4,
          type: SegmentComponentType.URL_VIEWED,
          description: 'has visited a specific page http://foo.com',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.VISITED_SITE,
            propertyAttributeCondition: SiteCondition.SPECIFIC_PAGE,
            pattern: 'http://foo.com',
          },
        },
      ],
    },
  ],
  description: 'The subscribers who meet these criteria are my favorites',
  id: 1234,
  name: 'My Favorite Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const complexSegmentMock: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 3,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.URL_VIEWED,
          description: 'has visited a specific page http://foo.com',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.VISITED_SITE,
            propertyAttributeCondition: SiteCondition.SPECIFIC_PAGE,
            pattern: 'http://foo.com',
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.AND,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
        {
          id: 215436,
          type: SegmentComponentType.BULK_LOCATION,
          description:
            'zipcode is 90201, 90209, 90210, 90211, 91505, 20203, 02020, 45643, 73505, or 73012, United States',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            subscriberProperty: PropertiesFilter.BULK_POSTAL_CODES,
            countryId: '2390',
            locations: [
              {
                value: '90201',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-CA',
              },
              {
                value: '90209',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-CA',
              },
              {
                value: '90210',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-CA',
              },
              {
                value: '90211',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-CA',
              },
              {
                value: '91505',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-CA',
              },
              {
                value: '20203',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-DC',
              },
              {
                value: '02020',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-MA',
              },
              {
                value: '45643',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-OH',
              },
              {
                value: '73505',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-OK',
              },
              {
                value: '73012',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-OK',
              },
              {
                value: '73530',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-OK',
              },
              {
                value: '19191',
                countryIsoCode: 'US',
                countryId: '2390',
                subdivisionIso: 'US-PA',
              },
            ],
          },
        },
      ],
    },
  ],
  description: 'The subscribers who meet these criteria are my favorites',
  id: 9202,
  name: 'A complex segment',
  operator: OperatorType.OR,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};
