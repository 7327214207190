import { Vendor, Feature, CompanyVendorIntegration } from '@attentive/data/types';

import { DeepPartial } from '../constants';

export type PartialCompanyVendorIntegrations = DeepPartial<CompanyVendorIntegration>;

export const klaviyoEmailIntegrationMock: PartialCompanyVendorIntegrations = {
  id: btoa('24:CompanyVendorIntegration1234'),
  externalVendorApi: {
    vendor: Vendor.Klaviyo,
    displayName: 'Klaviyo',
    externalVendorFeatures: [
      {
        type: Feature.FeatureSyncEmailEvents,
      },
    ],
  },
};

export const klaviyoSegmentIntegrationMock: PartialCompanyVendorIntegrations = {
  id: btoa('24:CompanyVendorIntegration1234'),
  externalVendorApi: {
    vendor: Vendor.Klaviyo,
    displayName: 'Klaviyo',
    externalVendorFeatures: [
      {
        type: Feature.SyncThirdPartyLists,
      },
    ],
  },
};

export const shopifyPurchaseEventsIntegrationMock: PartialCompanyVendorIntegrations = {
  id: btoa('24:CompanyVendorIntegration1234'),
  externalVendorApi: {
    vendor: Vendor.Shopify,
    displayName: 'Shopify',
    externalVendorFeatures: [
      {
        type: Feature.IngestTransactionalEvents,
      },
    ],
  },
};

export const shopifyAbandonedCheckoutsIntegrationMock: PartialCompanyVendorIntegrations = {
  id: btoa('24:CompanyVendorIntegration1234'),
  externalVendorApi: {
    vendor: Vendor.Shopify,
    displayName: 'Shopify',
    externalVendorFeatures: [
      {
        type: Feature.FeatureSyncAbandonedCheckouts,
      },
    ],
  },
};
