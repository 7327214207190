import {
  SubscriberAttributeDataType,
  FilterType,
  IsVerbType,
  SegmentOptions,
} from '../../constants';

import { KLAVIYO_VENDOR_INTEGRATION_ID } from './integrations';

export const segmentOptionsMock: SegmentOptions = {
  [FilterType.ACTION]: {
    ecommerceOptions: {
      productDataOptions: {
        attributeOptions: [
          {
            optionValue: 'PRODUCT_CATEGORY',
            displayName: 'Category',
          },
          {
            optionValue: 'PRODUCT_TAG',
            displayName: 'Tag',
          },
          {
            optionValue: 'PRODUCT_SIZE',
            displayName: 'Size',
          },
          {
            optionValue: 'PRODUCT_BRAND',
            displayName: 'Brand',
          },
          {
            optionValue: 'PRODUCT_NAME',
            displayName: 'Name',
          },
          {
            optionValue: 'PRODUCT_COLOR',
            displayName: 'Color',
          },
          {
            optionValue: 'PRODUCT_OPTION',
            displayName: 'Width',
          },
          {
            optionValue: 'PRODUCT_OPTION',
            displayName: 'Length',
          },
        ],
      },
      rechargeEventOptions: [
        { optionValue: 'ORDER_SUBSCRIPTION_CHARGE_PAID', displayName: 'subscription charged' },
        { optionValue: 'ORDER_SUBSCRIPTION_PROCESSED', displayName: 'order processed' },
      ],
      bazaarVoiceEventOptions: [
        { optionValue: 'COMPLETED_REVIEW', displayName: 'completed review' },
        { optionValue: 'LEFT_A_RATING', displayName: 'left a rating' },
      ],
      salesChannels: [],
    },
    customEventOptions: [
      {
        customEventType: 'custom event 1',
        customEventSource: 'ENTITY_TYPE_COMPANY_APPLICATION',
        customEventSourceId: '1',
        customEventValues: [
          {
            isVerb: IsVerbType.IS,
            customEventKey: 'some key',
          },
        ],
      },
      {
        customEventType: 'custom event 2',
        customEventSource: 'ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION',
        customEventSourceId: `${KLAVIYO_VENDOR_INTEGRATION_ID}`,
        customEventValues: [
          {
            isVerb: IsVerbType.IS,
            customEventKey: 'a different key',
          },
          {
            isVerb: IsVerbType.IS,
            customEventKey: 'another different key',
          },
        ],
      },
    ],
  },
  [FilterType.ATTRIBUTE]: {
    deviceOptions: {
      carriers: [
        {
          optionValue: 'TMOBILE',
          displayName: 'T-Mobile',
        },
      ],
      deviceTypes: [
        {
          optionValue: 'MOBILE',
          displayName: 'Mobile',
        },
        {
          optionValue: 'DESKTOP',
          displayName: 'Desktop',
        },
        {
          optionValue: 'TABLET',
          displayName: 'Tablet',
        },
        {
          optionValue: 'OTHER',
          displayName: 'Other',
        },
      ],
      operatingSystems: [
        {
          optionValue: 'ANDROID',
          displayName: 'Android',
        },
        {
          optionValue: 'CHROME_OS',
          displayName: 'ChromeOS',
        },
        {
          optionValue: 'IOS',
          displayName: 'iOS',
        },
        {
          optionValue: 'LINUX',
          displayName: 'Linux',
        },
        {
          optionValue: 'MAC_OS_X',
          displayName: 'MacOS',
        },
        {
          optionValue: 'WINDOWS',
          displayName: 'Windows',
        },
        {
          optionValue: 'OTHER',
          displayName: 'Other',
        },
      ],
    },
    demographicOptions: {
      countries: [
        { optionValue: 30081, displayName: '' },
        { optionValue: 159, displayName: 'Andorra' },
        { optionValue: 23, displayName: 'United Arab Emirates' },
        { optionValue: 63, displayName: 'Afghanistan' },
        { optionValue: 1154, displayName: 'Antigua and Barbuda' },
        { optionValue: 1059, displayName: 'Anguilla' },
        { optionValue: 46, displayName: 'Albania' },
        { optionValue: 10, displayName: 'Armenia' },
        { optionValue: 174, displayName: 'Angola' },
        { optionValue: 30146, displayName: 'Antarctica' },
        { optionValue: 1808, displayName: 'Argentina' },
        { optionValue: 27503, displayName: 'American Samoa' },
        { optionValue: 152, displayName: 'Austria' },
        { optionValue: 102, displayName: 'Australia' },
        { optionValue: 1191, displayName: 'Aruba' },
        { optionValue: 41, displayName: 'Åland' },
        { optionValue: 34, displayName: 'Azerbaijan' },
        { optionValue: 173, displayName: 'Bosnia and Herzegovina' },
        { optionValue: 178, displayName: 'Barbados' },
        { optionValue: 65, displayName: 'Bangladesh' },
        { optionValue: 153, displayName: 'Belgium' },
        { optionValue: 125, displayName: 'Burkina Faso' },
        { optionValue: 45, displayName: 'Bulgaria' },
        { optionValue: 22, displayName: 'Bahrain' },
        { optionValue: 31, displayName: 'Burundi' },
        { optionValue: 129, displayName: 'Benin' },
        { optionValue: 1224, displayName: 'Saint Barthélemy' },
        { optionValue: 1050, displayName: 'Bermuda' },
        { optionValue: 91, displayName: 'Brunei' },
        { optionValue: 1810, displayName: 'Bolivia' },
        {
          optionValue: 385,
          displayName: 'Bonaire, Sint Eustatius, and Saba',
        },
        { optionValue: 226, displayName: 'Brazil' },
        { optionValue: 1015, displayName: 'Bahamas' },
        { optionValue: 69, displayName: 'Bhutan' },
        { optionValue: 177, displayName: 'Bouvet Island' },
        { optionValue: 55, displayName: 'Botswana' },
        { optionValue: 39, displayName: 'Belarus' },
        { optionValue: 1268, displayName: 'Belize' },
        { optionValue: 27504, displayName: 'Canada' },
        { optionValue: 80, displayName: 'Cocos [Keeling] Islands' },
        { optionValue: 12, displayName: 'Congo' },
        { optionValue: 15, displayName: 'Central African Republic' },
        { optionValue: 118, displayName: 'Republic of the Congo' },
        { optionValue: 149, displayName: 'Switzerland' },
        { optionValue: 121, displayName: 'Ivory Coast' },
        { optionValue: 98, displayName: 'Cook Islands' },
        { optionValue: 1809, displayName: 'Chile' },
        { optionValue: 116, displayName: 'Cameroon' },
        { optionValue: 89, displayName: 'China' },
        { optionValue: 1645, displayName: 'Colombia' },
        { optionValue: 1518, displayName: 'Costa Rica' },
        { optionValue: 979, displayName: 'Cuba' },
        { optionValue: 198, displayName: 'Cabo Verde' },
        { optionValue: 384, displayName: 'Curaçao' },
        { optionValue: 103, displayName: 'Christmas Island' },
        { optionValue: 7, displayName: 'Cyprus' },
        { optionValue: 165, displayName: 'Czechia' },
        { optionValue: 154, displayName: 'Germany' },
        { optionValue: 13, displayName: 'Djibouti' },
        { optionValue: 146, displayName: 'Denmark' },
        { optionValue: 1143, displayName: 'Dominica' },
        { optionValue: 267, displayName: 'Dominican Republic' },
        { optionValue: 144, displayName: 'Algeria' },
        { optionValue: 1644, displayName: 'Ecuador' },
        { optionValue: 32, displayName: 'Estonia' },
        { optionValue: 28, displayName: 'Egypt' },
        { optionValue: 139, displayName: 'Western Sahara' },
        { optionValue: 27, displayName: 'Eritrea' },
        { optionValue: 141, displayName: 'Spain' },
        { optionValue: 26, displayName: 'Ethiopia' },
        { optionValue: 40, displayName: 'Finland' },
        { optionValue: 114, displayName: 'Fiji' },
        { optionValue: 227, displayName: 'Falkland Islands' },
        { optionValue: 105, displayName: 'Federated States of Micronesia' },
        { optionValue: 145, displayName: 'Faroe Islands' },
        { optionValue: 158, displayName: 'France' },
        { optionValue: 130, displayName: 'Gabon' },
        { optionValue: 148, displayName: 'United Kingdom' },
        { optionValue: 1248, displayName: 'Grenada' },
        { optionValue: 37, displayName: 'Georgia' },
        { optionValue: 200, displayName: 'French Guiana' },
        { optionValue: 163, displayName: 'Guernsey' },
        { optionValue: 122, displayName: 'Ghana' },
        { optionValue: 133, displayName: 'Gibraltar' },
        { optionValue: 202, displayName: 'Greenland' },
        { optionValue: 134, displayName: 'Gambia' },
        { optionValue: 135, displayName: 'Guinea' },
        { optionValue: 1215, displayName: 'Guadeloupe' },
        { optionValue: 123, displayName: 'Equatorial Guinea' },
        { optionValue: 30, displayName: 'Greece' },
        {
          optionValue: 228,
          displayName: 'South Georgia and the South Sandwich Islands',
        },
        { optionValue: 1351, displayName: 'Guatemala' },
        { optionValue: 2389, displayName: 'Guam' },
        { optionValue: 127, displayName: 'Guinea-Bissau' },
        { optionValue: 199, displayName: 'Guyana' },
        { optionValue: 90, displayName: 'Hong Kong' },
        {
          optionValue: 79,
          displayName: 'Heard Island and McDonald Islands',
        },
        { optionValue: 1421, displayName: 'Honduras' },
        { optionValue: 172, displayName: 'Croatia' },
        { optionValue: 1693, displayName: 'Haiti' },
        { optionValue: 44, displayName: 'Hungary' },
        { optionValue: 84, displayName: 'Indonesia' },
        { optionValue: 156, displayName: 'Ireland' },
        { optionValue: 24, displayName: 'Israel' },
        { optionValue: 162, displayName: 'Isle of Man' },
        { optionValue: 70, displayName: 'India' },
        { optionValue: 72, displayName: 'British Indian Ocean Territory' },
        { optionValue: 4, displayName: 'Iraq' },
        { optionValue: 6, displayName: 'Iran' },
        { optionValue: 147, displayName: 'Iceland' },
        { optionValue: 169, displayName: 'Italy' },
        { optionValue: 161, displayName: 'Jersey' },
        { optionValue: 233, displayName: 'Jamaica' },
        { optionValue: 17, displayName: 'Hashemite Kingdom of Jordan' },
        { optionValue: 95, displayName: 'Japan' },
        { optionValue: 11, displayName: 'Kenya' },
        { optionValue: 77, displayName: 'Kyrgyzstan' },
        { optionValue: 93, displayName: 'Cambodia' },
        { optionValue: 2382, displayName: 'Kiribati' },
        { optionValue: 52, displayName: 'Comoros' },
        { optionValue: 1128, displayName: 'St Kitts and Nevis' },
        { optionValue: 96, displayName: 'North Korea' },
        { optionValue: 94, displayName: 'Republic of Korea' },
        { optionValue: 19, displayName: 'Kuwait' },
        { optionValue: 1261, displayName: 'Cayman Islands' },
        { optionValue: 76, displayName: 'Kazakhstan' },
        { optionValue: 85, displayName: 'Laos' },
        { optionValue: 18, displayName: 'Lebanon' },
        { optionValue: 1169, displayName: 'Saint Lucia' },
        { optionValue: 160, displayName: 'Liechtenstein' },
        { optionValue: 68, displayName: 'Sri Lanka' },
        { optionValue: 120, displayName: 'Liberia' },
        { optionValue: 54, displayName: 'Lesotho' },
        { optionValue: 35, displayName: 'Republic of Lithuania' },
        { optionValue: 155, displayName: 'Luxembourg' },
        { optionValue: 33, displayName: 'Latvia' },
        { optionValue: 115, displayName: 'Libya' },
        { optionValue: 142, displayName: 'Morocco' },
        { optionValue: 157, displayName: 'Monaco' },
        { optionValue: 38, displayName: 'Republic of Moldova' },
        { optionValue: 171, displayName: 'Montenegro' },
        { optionValue: 1219, displayName: 'Saint Martin' },
        { optionValue: 62, displayName: 'Madagascar' },
        { optionValue: 104, displayName: 'Marshall Islands' },
        { optionValue: 43, displayName: 'Macedonia' },
        { optionValue: 138, displayName: 'Mali' },
        { optionValue: 74, displayName: 'Myanmar' },
        { optionValue: 101, displayName: 'Mongolia' },
        { optionValue: 92, displayName: 'Macao' },
        { optionValue: 2388, displayName: 'Northern Mariana Islands' },
        { optionValue: 991, displayName: 'Martinique' },
        { optionValue: 128, displayName: 'Mauritania' },
        { optionValue: 1212, displayName: 'Montserrat' },
        { optionValue: 143, displayName: 'Malta' },
        { optionValue: 56, displayName: 'Mauritius' },
        { optionValue: 71, displayName: 'Maldives' },
        { optionValue: 53, displayName: 'Malawi' },
        { optionValue: 229, displayName: 'Mexico' },
        { optionValue: 88, displayName: 'Malaysia' },
        { optionValue: 61, displayName: 'Mozambique' },
        { optionValue: 175, displayName: 'Namibia' },
        { optionValue: 111, displayName: 'New Caledonia' },
        { optionValue: 137, displayName: 'Niger' },
        { optionValue: 112, displayName: 'Norfolk Island' },
        { optionValue: 124, displayName: 'Nigeria' },
        { optionValue: 1486, displayName: 'Nicaragua' },
        { optionValue: 151, displayName: 'Netherlands' },
        { optionValue: 166, displayName: 'Norway' },
        { optionValue: 73, displayName: 'Nepal' },
        { optionValue: 109, displayName: 'Nauru' },
        { optionValue: 2387, displayName: 'Niue' },
        { optionValue: 113, displayName: 'New Zealand' },
        { optionValue: 20, displayName: 'Oman' },
        { optionValue: 1646, displayName: 'Panama' },
        { optionValue: 1811, displayName: 'Peru' },
        { optionValue: 2380, displayName: 'French Polynesia' },
        { optionValue: 106, displayName: 'Papua New Guinea' },
        { optionValue: 87, displayName: 'Philippines' },
        { optionValue: 64, displayName: 'Pakistan' },
        { optionValue: 47, displayName: 'Poland' },
        { optionValue: 220, displayName: 'Saint Pierre and Miquelon' },
        { optionValue: 2381, displayName: 'Pitcairn Islands' },
        { optionValue: 9839, displayName: 'Puerto Rico' },
        { optionValue: 30080, displayName: 'Palestine' },
        { optionValue: 119, displayName: 'Portugal' },
        { optionValue: 81, displayName: 'Palau' },
        { optionValue: 224, displayName: 'Paraguay' },
        { optionValue: 21, displayName: 'Qatar' },
        { optionValue: 58, displayName: 'Réunion' },
        { optionValue: 48, displayName: 'Romania' },
        { optionValue: 30083, displayName: 'Serbia' },
        { optionValue: 100, displayName: 'Russia' },
        { optionValue: 1, displayName: 'Rwanda' },
        { optionValue: 5, displayName: 'Saudi Arabia' },
        { optionValue: 107, displayName: 'Solomon Islands' },
        { optionValue: 16, displayName: 'Seychelles' },
        { optionValue: 29, displayName: 'Sudan' },
        { optionValue: 150, displayName: 'Sweden' },
        { optionValue: 97, displayName: 'Singapore' },
        { optionValue: 176, displayName: 'Saint Helena' },
        { optionValue: 170, displayName: 'Slovenia' },
        { optionValue: 36, displayName: 'Svalbard and Jan Mayen' },
        { optionValue: 164, displayName: 'Slovakia' },
        { optionValue: 131, displayName: 'Sierra Leone' },
        { optionValue: 168, displayName: 'San Marino' },
        { optionValue: 117, displayName: 'Senegal' },
        { optionValue: 2, displayName: 'Somalia' },
        { optionValue: 201, displayName: 'Suriname' },
        { optionValue: 30329, displayName: 'South Sudan' },
        { optionValue: 132, displayName: 'São Tomé and Príncipe' },
        { optionValue: 1284, displayName: 'El Salvador' },
        { optionValue: 386, displayName: 'Sint Maarten' },
        { optionValue: 9, displayName: 'Syria' },
        { optionValue: 57, displayName: 'Eswatini' },
        { optionValue: 1188, displayName: 'Turks and Caicos Islands' },
        { optionValue: 136, displayName: 'Chad' },
        { optionValue: 78, displayName: 'French Southern Territories' },
        { optionValue: 126, displayName: 'Togo' },
        { optionValue: 83, displayName: 'Thailand' },
        { optionValue: 67, displayName: 'Tajikistan' },
        { optionValue: 2383, displayName: 'Tokelau' },
        {
          optionValue: 99,
          displayName: 'Democratic Republic of Timor-Leste',
        },
        { optionValue: 66, displayName: 'Turkmenistan' },
        { optionValue: 140, displayName: 'Tunisia' },
        { optionValue: 2384, displayName: 'Tonga' },
        { optionValue: 25, displayName: 'Turkey' },
        { optionValue: 1062, displayName: 'Trinidad and Tobago' },
        { optionValue: 108, displayName: 'Tuvalu' },
        { optionValue: 86, displayName: 'Taiwan' },
        { optionValue: 8, displayName: 'Tanzania' },
        { optionValue: 42, displayName: 'Ukraine' },
        { optionValue: 14, displayName: 'Uganda' },
        { optionValue: 27332, displayName: 'U.S. Minor Outlying Islands' },
        { optionValue: 2390, displayName: 'United States' },
        { optionValue: 225, displayName: 'Uruguay' },
        { optionValue: 75, displayName: 'Uzbekistan' },
        { optionValue: 167, displayName: 'Vatican City' },
        {
          optionValue: 1203,
          displayName: 'Saint Vincent and the Grenadines',
        },
        { optionValue: 1643, displayName: 'Venezuela' },
        { optionValue: 1201, displayName: 'British Virgin Islands' },
        { optionValue: 12455, displayName: 'U.S. Virgin Islands' },
        { optionValue: 82, displayName: 'Vietnam' },
        { optionValue: 110, displayName: 'Vanuatu' },
        { optionValue: 2385, displayName: 'Wallis and Futuna' },
        { optionValue: 2386, displayName: 'Samoa' },
        { optionValue: 49, displayName: 'Kosovo' },
        { optionValue: 3, displayName: 'Yemen' },
        { optionValue: 60, displayName: 'Mayotte' },
        { optionValue: 59, displayName: 'South Africa' },
        { optionValue: 51, displayName: 'Zambia' },
        { optionValue: 50, displayName: 'Zimbabwe' },
      ],
      states: [
        {
          optionValue: 2062,
          displayName: 'Alabama',
        },
        {
          optionValue: 24772,
          displayName: 'Alaska',
        },
        {
          optionValue: 22588,
          displayName: 'Arizona',
        },
        {
          optionValue: 2081,
          displayName: 'Arkansas',
        },
        {
          optionValue: 22592,
          displayName: 'California',
        },
        {
          optionValue: 23802,
          displayName: 'Colorado',
        },
        {
          optionValue: 12584,
          displayName: 'Connecticut',
        },
        {
          optionValue: 2084,
          displayName: 'Delaware',
        },
        {
          optionValue: 3007,
          displayName: 'District of Columbia',
        },
        {
          optionValue: 2086,
          displayName: 'Florida',
        },
        {
          optionValue: 2088,
          displayName: 'Georgia',
        },
        {
          optionValue: 26856,
          displayName: 'Hawaii',
        },
        {
          optionValue: 24776,
          displayName: 'Idaho',
        },
        {
          optionValue: 2090,
          displayName: 'Illinois',
        },
        {
          optionValue: 2091,
          displayName: 'Indiana',
        },
        {
          optionValue: 12588,
          displayName: 'Iowa',
        },
        {
          optionValue: 2098,
          displayName: 'Kansas',
        },
        {
          optionValue: 2097,
          displayName: 'Kentucky',
        },
        {
          optionValue: 2105,
          displayName: 'Louisiana',
        },
        {
          optionValue: 12612,
          displayName: 'Maine',
        },
        {
          optionValue: 2096,
          displayName: 'Maryland',
        },
        {
          optionValue: 12606,
          displayName: 'Massachusetts',
        },
        {
          optionValue: 12621,
          displayName: 'Michigan',
        },
        {
          optionValue: 12625,
          displayName: 'Minnesota',
        },
        {
          optionValue: 2112,
          displayName: 'Mississippi',
        },
        {
          optionValue: 2109,
          displayName: 'Missouri',
        },
        {
          optionValue: 24782,
          displayName: 'Montana',
        },
        {
          optionValue: 12630,
          displayName: 'Nebraska',
        },
        {
          optionValue: 24348,
          displayName: 'Nevada',
        },
        {
          optionValue: 17608,
          displayName: 'New Hampshire',
        },
        {
          optionValue: 8389,
          displayName: 'New Jersey',
        },
        {
          optionValue: 22602,
          displayName: 'New Mexico',
        },
        {
          optionValue: 12633,
          displayName: 'New York',
        },
        {
          optionValue: 2113,
          displayName: 'North Carolina',
        },
        {
          optionValue: 17085,
          displayName: 'North Dakota',
        },
        {
          optionValue: 2120,
          displayName: 'Ohio',
        },
        {
          optionValue: 2121,
          displayName: 'Oklahoma',
        },
        {
          optionValue: 24787,
          displayName: 'Oregon',
        },
        {
          optionValue: 9320,
          displayName: 'Pennsylvania',
        },
        {
          optionValue: 21328,
          displayName: 'Rhode Island',
        },
        {
          optionValue: 2122,
          displayName: 'South Carolina',
        },
        {
          optionValue: 12662,
          displayName: 'South Dakota',
        },
        {
          optionValue: 2124,
          displayName: 'Tennessee',
        },
        {
          optionValue: 2060,
          displayName: 'Texas',
        },
        {
          optionValue: 22605,
          displayName: 'Utah',
        },
        {
          optionValue: 21597,
          displayName: 'Vermont',
        },
        {
          optionValue: 2069,
          displayName: 'Virginia',
        },
        {
          optionValue: 24795,
          displayName: 'Washington',
        },
        {
          optionValue: 2076,
          displayName: 'West Virginia',
        },
        {
          optionValue: 12663,
          displayName: 'Wisconsin',
        },
        {
          optionValue: 26704,
          displayName: 'Wyoming',
        },
      ],
    },
    emailOptions: {
      creativeNameToIds: {
        'A sign-up unit': 3800,
        'Another sign-up unit': 3801,
      },
    },
    smsOptions: {
      companyCodes: ['COMPANYCODEONE', 'COMPANYCODETWO'],
      creativeNameToIds: {
        'A sign-up unit': 3800,
      },
      subscriberPreferenceGroups: [
        {
          optionValue: 'VIP Club',
          displayName: 'Sign up for our VIP club',
          preferences: [
            {
              optionValue: 'VIP Club',
              displayName: 'Sign up for our VIP club',
            },
            {
              optionValue: 'tranch1',
              displayName: 'Tranche 1',
            },
            {
              optionValue: 'tranch2',
              displayName: 'Tranche 2',
            },
          ],
        },
        {
          optionValue: 'some_preference',
          displayName: 'My cool preference',
          preferences: [
            {
              optionValue: 'value1',
              displayName: 'Value 1',
            },
            {
              optionValue: 'value2',
              displayName: 'Value 2',
            },
          ],
        },
      ],
    },
    shopifyOptions: {
      acceptsMarketingOptions: [
        {
          optionValue: 'TRUE',
          displayName: 'True',
        },
        {
          optionValue: 'FALSE',
          displayName: 'False',
        },
      ],
      marketingOptInLevelOptions: [
        {
          optionValue: 'MARKETING_OPT_IN_LEVEL_NOT_SET',
          displayName: 'Not Set',
        },
        {
          optionValue: 'SINGLE_OPT_IN',
          displayName: 'Single Opt In',
        },
        {
          optionValue: 'CONFIRMED_OPT_IN',
          displayName: 'Confirmed Opt In',
        },
        {
          optionValue: 'UNKNOWN',
          displayName: 'Unknown',
        },
      ],
      customerTagOptions: [
        {
          optionValue: '22 Jan 2020 23:12:17 GMT',
          displayName: '22 Jan 2020 23:12:17 GMT',
        },
        {
          optionValue: 'Active Subscriber',
          displayName: 'Active Subscriber',
        },
        {
          optionValue: 'BrushCampaign',
          displayName: 'BrushCampaign',
        },
        {
          optionValue: 'General',
          displayName: 'General',
        },
      ],
      currencyOptions: [
        {
          optionValue: 'USD',
          displayName: 'USD',
        },
      ],
      customerStateOptions: [
        {
          optionValue: 'CUSTOMER_STATE_NOT_SET',
          displayName: 'Not Set',
        },
        {
          optionValue: 'DISABLED',
          displayName: 'Disabled',
        },
        {
          optionValue: 'INVITED',
          displayName: 'Invited',
        },
        {
          optionValue: 'ENABLED',
          displayName: 'Enabled',
        },
        {
          optionValue: 'DECLINED',
          displayName: 'Declined',
        },
      ],
    },
    customAttributeOptions: [
      {
        optionValue: 'some deleted custom attribute',
        displayName: 'testing-deleted-1617396555493',
        type: SubscriberAttributeDataType.ENUM,
        values: [
          {
            optionValue: 'something',
            displayName: 'something',
            deleted: true,
          },
          {
            optionValue: 'something else',
            displayName: 'something else',
            deleted: false,
          },
        ],
        deleted: true,
      },
      {
        optionValue: 'some custom attribute',
        displayName: 'some custom attribute',
        type: SubscriberAttributeDataType.ENUM,
        values: [
          {
            optionValue: 'something',
            displayName: 'something',
            deleted: false,
          },
          {
            optionValue: 'something else',
            displayName: 'something else',
            deleted: false,
          },
        ],
        deleted: false,
      },
      {
        optionValue: '20130',
        displayName: 'Octane: What is your fragrance volume?',
        values: [],
        type: SubscriberAttributeDataType.TEXT,
        deleted: false,
      },
      {
        optionValue: 'another custom attribute',
        displayName: 'A super cool custom attribute',
        type: SubscriberAttributeDataType.TEXT,
        deleted: false,
        values: [
          {
            optionValue: 'something',
            displayName: 'something',
            deleted: false,
          },
          {
            optionValue: 'something else',
            displayName: 'something else',
            deleted: false,
          },
        ],
      },
      {
        optionValue: 'my option value',
        displayName: 'my display value',
        values: [],
        type: SubscriberAttributeDataType.INTEGER,
        deleted: false,
      },
      {
        optionValue: 'custom attribute text',
        displayName: 'custom attribute text',
        type: SubscriberAttributeDataType.TEXT,
        deleted: false,
        values: [
          {
            optionValue: 'something',
            displayName: 'something',
            deleted: false,
          },
          {
            optionValue: 'something else',
            displayName: 'something else',
            deleted: false,
          },
        ],
      },
      {
        optionValue: '213',
        displayName: 'Favorite Paper Towel Brand',
        type: SubscriberAttributeDataType.TEXT,
        deleted: false,
        values: [],
      },
      {
        optionValue: 'custom attribute enum',
        displayName: 'custom attribute enum',
        type: SubscriberAttributeDataType.ENUM,
        deleted: false,
        values: [
          {
            optionValue: 'something',
            displayName: 'something',
            deleted: false,
          },
          {
            optionValue: 'something else',
            displayName: 'something else',
            deleted: false,
          },
        ],
      },
      {
        optionValue: 'custom attribute number',
        displayName: 'custom attribute number',
        values: [],
        type: SubscriberAttributeDataType.INTEGER,
        deleted: false,
      },
      {
        optionValue: 'custom attribute boolean',
        displayName: 'custom attribute boolean',
        values: [],
        type: SubscriberAttributeDataType.BOOLEAN,
        deleted: false,
      },
      {
        optionValue: 'custom attribute timestamp',
        displayName: 'custom attribute timestamp',
        values: [],
        type: SubscriberAttributeDataType.TIMESTAMP,
        deleted: false,
      },
    ],
    wellKnownAttributeOptions: [
      {
        optionValue: 'WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING',
        displayName: 'Mailchimp Member Rating',
        type: SubscriberAttributeDataType.INTEGER,
      },
      {
        optionValue: 'WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN',
        displayName: 'Klaviyo Email Last Opened',
        type: SubscriberAttributeDataType.TIMESTAMP,
      },
      {
        optionValue: 'WELL_KNOWN_PROPERTY_COUNTRY',
        displayName: 'Country, residence',
        type: SubscriberAttributeDataType.TEXT,
        values: [
          {
            optionValue: 'AD',
            displayName: 'Andorra',
            deleted: false,
          },
          {
            optionValue: 'AE',
            displayName: 'United Arab Emirates',
            deleted: false,
          },
          {
            optionValue: 'US',
            displayName: 'United States',
            deleted: false,
          },
        ],
      },
      {
        optionValue: 'WELL_KNOWN_PROPERTY_MAIL_EXCHANGE',
        displayName: 'Mail Exchange',
        type: SubscriberAttributeDataType.TEXT,
        values: [
          {
            optionValue: 'UNMAPPED',
            displayName: 'Not Mapped',
            deleted: false,
          },
          {
            optionValue: 'GOOGLE',
            displayName: 'Gmail,Google',
            deleted: false,
          },
          {
            optionValue: 'YAHOO_AOL',
            displayName: 'Yahoo,AOL',
            deleted: false,
          },
          {
            optionValue: 'MICROSOFT',
            displayName: 'Outlook, Hotmail, MSN, Live',
            deleted: false,
          },
          {
            optionValue: 'APPLE',
            displayName: 'iCloud, ME',
            deleted: false,
          },
          {
            optionValue: 'PRODIGY',
            displayName: 'Prodigy',
            deleted: false,
          },
          {
            optionValue: 'COMCAST',
            displayName: 'Comcast',
            deleted: false,
          },
          {
            optionValue: 'CHARTER',
            displayName: 'Charter',
            deleted: false,
          },
          {
            optionValue: 'OTHER',
            displayName: 'Other',
            deleted: false,
          },
        ],
      },
      {
        optionValue: 'WELL_KNOWN_PROPERTY_LOCALE',
        displayName: 'Locale',
        type: SubscriberAttributeDataType.OBJECT,
        values: [],
        nestedOptions: [
          {
            attributeName: 'language',
            displayName: 'Language',
            type: SubscriberAttributeDataType.TEXT,
            values: [
              {
                optionValue: 'cy',
                displayName: 'Welsh',
                deleted: false,
              },
              {
                optionValue: 'en',
                displayName: 'English',
                deleted: false,
              },
              {
                optionValue: 'fr',
                displayName: 'French',
                deleted: false,
              },
              {
                optionValue: 'es',
                displayName: 'Spanish',
                deleted: false,
              },
            ],
          },
        ],
      },
    ],
  },
  [FilterType.MEMBERSHIP]: {
    subscriberTags: ['TAG1', 'TAG2'],
    manualUploadLists: [
      {
        subscriberListMetadataId: 1,
        name: 'a manual upload list',
      },
      {
        subscriberListMetadataId: 811,
        name: 'manual list',
      },
    ],
    thirdPartyLists: [
      {
        subscriberListMetadataId: 285,
        name: 'Something third party',
        externalId: 'JzVJ6j',
        externalUrl: 'https://foorl.com',
        externalSource: 'Klaviyo',
        matchRate: 50,
        syncedAt: 1589928887,
      },
      {
        subscriberListMetadataId: 411,
        name: 'Test Third Party Lists',
        externalId: 'VVXh8N',
        externalUrl: 'https://foorl2.com',
        externalSource: 'Klaviyo',
        matchRate: 50,
        syncedAt: 1589928887,
      },
    ],
  },
};

export const emptyAttributeOptions = {
  deviceOptions: {
    carriers: [],
    deviceTypes: [],
    operatingSystems: [],
  },
  demographicOptions: {
    countries: [],
    states: [],
  },
  emailOptions: {
    creativeNameToIds: {},
  },
  smsOptions: {
    companyCodes: [],
    creativeNameToIds: {},
    subscriberPreferenceGroups: [],
  },
  shopifyOptions: {
    acceptsMarketingOptions: [],
    marketingOptInLevelOptions: [],
    customerTagOptions: [],
    currencyOptions: [],
    customerStateOptions: [],
  },
  customAttributeOptions: [],
  wellKnownAttributeOptions: [],
};
