import React, { FC, useState } from 'react';

import { updateSegment } from '../api';
import { Segment, ThinSegment } from '../constants';
import { useSegmentCreateEdit, useUserIsDemoUser } from '../hooks';

import SegmentEditTitleDialog from './SegmentEditTitleDialog';

interface SegmentEditTitleOnlyModalProps {
  onOpenChange: (val: boolean) => void;
  id: number;
  passedSegment?: ThinSegment | Segment;
  onSave?: (segment: Segment) => void;
  onError?: (error: Error) => void;
}

const SegmentEditTitleOnlyModal: FC<SegmentEditTitleOnlyModalProps> = ({
  onOpenChange,
  id,
  passedSegment,
  onSave,
  onError,
}) => {
  const isDemoUser = useUserIsDemoUser();
  const { segment, updateCurrentSegment } = useSegmentCreateEdit(id, passedSegment, isDemoUser);
  const [isSaving, setIsSaving] = useState(false);

  const submitSegment = async () => {
    setIsSaving(true);
    try {
      const response = await updateSegment(segment as Segment);
      const { body, status } = response;
      if (status !== 200) {
        throw new Error('Failed to save segment');
      }
      if (!body) {
        throw new Error('Unexpected empty response');
      }
      if (onSave) {
        onSave(body);
      }
      onOpenChange(false);
      setIsSaving(false);
    } catch (err) {
      if (onError) {
        onError(err);
      }
      setIsSaving(false);
    }
  };

  return (
    <SegmentEditTitleDialog
      dialogTitle="Edit name or description"
      onOpenChange={onOpenChange}
      name={segment.name}
      handleNameChange={(name) => updateCurrentSegment({ name })}
      description={segment.description}
      handleDescriptionChange={(description) => updateCurrentSegment({ description })}
      handleSubmit={submitSegment}
      isSaving={isSaving}
    />
  );
};

export default SegmentEditTitleOnlyModal;
