import { isBefore } from 'date-fns';
import { useState } from 'react';

import { CompanyFeatureFlags, useCompanyFeatureFlag } from '@attentive/acore-utils';

export const useNewFeatureAnnouncement = (
  featureFlag: keyof CompanyFeatureFlags,
  expirationDate?: Date
) => {
  const featureIsEnabled = useCompanyFeatureFlag(featureFlag);
  const storageKey = `hasSeenAnnouncement__${featureFlag}`;

  const isOpenInitial =
    featureIsEnabled &&
    !localStorage.getItem(storageKey) &&
    (!expirationDate || isBefore(new Date(), expirationDate));

  const [isOpen, setIsOpen] = useState(isOpenInitial);

  const setHasSeen = () => {
    localStorage.setItem(storageKey, 'true');
  };

  const dismiss = () => {
    setHasSeen();
    setIsOpen(false);
  };

  return { isOpen, setIsOpen, dismiss };
};
