import { mswRest } from '@attentive/mock-data';

import {
  mockEmptyResponse,
  mockResponsePage0,
  mockResponsePage1,
  getPaginatedDressResponse,
  getPaginatedFakeResponse,
} from '../data/productAttributes';

interface SearchParams {
  attribute: string;
  offset: string;
  query: string;
}

const getResponse = ({ attribute, offset, query }: SearchParams) => {
  if (attribute !== 'PRODUCT_ATTRIBUTE_NAME') return mockEmptyResponse;
  if (query?.toLowerCase() === 'dress') return getPaginatedDressResponse(Number(offset));
  // this query is for testing that more than 1000 items can't be selected.
  if (query?.toLowerCase() === 'fake') return getPaginatedFakeResponse(1000);
  // this query is for testing that over 1000 results will not auto load or allow select all
  if (query?.toLowerCase() === 'too many') return getPaginatedFakeResponse(1020);
  if (query) return mockEmptyResponse;
  if (offset === '20') return mockResponsePage1;
  return mockResponsePage0;
};

export const getProductAttributes = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/products/v1/attributes',
  (req, res, ctx) => {
    const searchParams = req.url.searchParams;
    const attribute = searchParams.get('attribute') as string;
    const offset = searchParams.get('offset') as string;
    const query = searchParams.get('query') as string;

    return res(ctx.delay(0), ctx.status(200), ctx.json(getResponse({ attribute, offset, query })));
  }
);
