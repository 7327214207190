import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { KlaviyoCampaign } from '../../components';
import { mockKlaviyoCampaignsResponse } from '../data/klaviyoCampaigns';

export interface getKlaviyoCampaignsHeaders {
  delay?: number;
  response?: KlaviyoCampaign[];
  status?: number;
}
export const getKlaviyoCampaigns = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options/third-party-campaigns',
  (req, res, ctx) => {
    const {
      delay = 0,
      response = mockKlaviyoCampaignsResponse,
      status = 200,
    } = parseMSWHeaders<getKlaviyoCampaignsHeaders>(req.headers);

    return res(ctx.delay(delay), ctx.status(status), ctx.json(response));
  }
);
