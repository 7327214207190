/**
 * @generated SignedSource<<618633b89bd660c03e187ccfa3a7edd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment$data = {
  readonly externalIdResponse?: {
    readonly customPropertyId?: {
      readonly id: string;
    };
    readonly id?: string;
    readonly name?: string;
  } | null;
  readonly internalIdResponse?: {
    readonly customPropertyDefinitionByInternalId?: {
      readonly customPropertyId: {
        readonly id: string;
      };
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentType": "useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment";
};
export type useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment$key = {
  readonly " $data"?: useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomPropertyID",
    "kind": "LinkedField",
    "name": "customPropertyId",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasSelectedInternalIdValue"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasSelectedNodeIdValue"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedInternalIdValue"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedNodeValue"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment",
  "selections": [
    {
      "condition": "hasSelectedInternalIdValue",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "internalIdResponse",
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "companyId"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "internalId",
                      "variableName": "selectedInternalIdValue"
                    }
                  ],
                  "concreteType": "CustomPropertyDefinition",
                  "kind": "LinkedField",
                  "name": "customPropertyDefinitionByInternalId",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasSelectedNodeIdValue",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "externalIdResponse",
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "selectedNodeValue"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "CustomPropertyDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b991af993e938586f2f0ef499d2b13f7";

export default node;
