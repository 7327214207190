import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { IntegrationsResponse, ThirdPartyIntegration } from '../../constants/interfaces';
import {
  fetchKlaviyoIntegrationDetailsMock,
  fetchShopifyIntegrationDetailsMock,
  fetchIntegrationsMock,
  KLAVIYO_VENDOR_INTEGRATION_ID,
  SHOPIFY_VENDOR_INTEGRATION_ID,
} from '../data/integrations';

interface GetIntegrationsHeaders {
  getIntegrationsDelay?: number;
  getIntegrationsResponse?: IntegrationsResponse;
  getIntegrationsStatus?: number;
}

export const getIntegrations = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/integrations',
  (req, res, ctx) => {
    const {
      getIntegrationsDelay = 0,
      getIntegrationsResponse = fetchIntegrationsMock,
      getIntegrationsStatus = 200,
    }: GetIntegrationsHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getIntegrationsDelay),
      ctx.status(getIntegrationsStatus),
      ctx.json(getIntegrationsResponse)
    );
  }
);

interface GetKlaviyoIntegrationHeaders {
  getKlaviyoIntegrationDelay?: number;
  getKlaviyoIntegrationResponse?: ThirdPartyIntegration;
  getKlaviyoIntegrationStatus?: number;
}

export const getKlaviyoIntegration = mswRest.get(
  `https://ui-api-devel.attentivemobile.com/integrations/${KLAVIYO_VENDOR_INTEGRATION_ID}`,
  (req, res, ctx) => {
    const {
      getKlaviyoIntegrationDelay = 0,
      getKlaviyoIntegrationResponse = fetchKlaviyoIntegrationDetailsMock,
      getKlaviyoIntegrationStatus = 200,
    }: GetKlaviyoIntegrationHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getKlaviyoIntegrationDelay),
      ctx.status(getKlaviyoIntegrationStatus),
      ctx.json(getKlaviyoIntegrationResponse)
    );
  }
);

interface GetShopifyIntegrationHeaders {
  getShopifyIntegrationDelay?: number;
  getShopifyIntegrationResponse?: ThirdPartyIntegration;
  getShopifyIntegrationStatus?: number;
}

export const getShopifyIntegration = mswRest.get(
  `https://ui-api-devel.attentivemobile.com/integrations/${SHOPIFY_VENDOR_INTEGRATION_ID}`,
  (req, res, ctx) => {
    const {
      getShopifyIntegrationDelay = 0,
      getShopifyIntegrationResponse = fetchShopifyIntegrationDetailsMock,
      getShopifyIntegrationStatus = 200,
    }: GetShopifyIntegrationHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getShopifyIntegrationDelay),
      ctx.status(getShopifyIntegrationStatus),
      ctx.json(getShopifyIntegrationResponse)
    );
  }
);
