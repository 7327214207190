import React, { FC } from 'react';

import { Link, PicnicCss, Text } from '@attentive/picnic';

// This regex is ported over from code/web which checks for a URL with or without the protocol.
const PROTOCOL_OPTIONAL_URL_REGEX =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

interface OwnProps {
  words: string[];
  css?: PicnicCss;
  stringReplacements: Record<string, string>;
}

const TextWithLinks: FC<OwnProps> = ({ words, css, stringReplacements }) => {
  return (
    <Text css={{ display: 'inline', ...css }}>
      {words.map((word, wordIdx) => {
        const wordKey = `${word}-${wordIdx}`;
        const displayedWord = stringReplacements[word] || word;

        return PROTOCOL_OPTIONAL_URL_REGEX.test(word) ? (
          <React.Fragment key={wordKey}>
            <Link href={`${word}`} target="_blank">
              {word}
            </Link>{' '}
          </React.Fragment>
        ) : (
          `${displayedWord} `
        );
      })}
    </Text>
  );
};

export default TextWithLinks;
