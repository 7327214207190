import { MockDataOverrideDecorator } from '@attentive/mock-data';

import Query, {
  ExistingCustomAttributeDialog_TargetingCommon_Query as QueryType,
} from '../../components/CustomAttributeDialog/components/ExistingCustomAttributeDialog/__generated__/ExistingCustomAttributeDialog_TargetingCommon_Query.graphql';
import { mockCustomAttributes } from '../data/customAttributes';

import { CustomAttributeUsageArgs } from './CustomAttributeUsage';

const booleanAttribute: QueryType['rawResponse'] = {
  customAttribute: {
    id: 'CustomPropertyDefinition-boolean-attribute',
    __typename: 'CustomPropertyDefinition',
    name: 'A boolean attribute',
    propertyType: 'TYPE_BOOL',
    typeDefinition: null,
    usages: [],
  },
};

const dateAttribute: QueryType['rawResponse'] = {
  customAttribute: {
    id: 'CustomPropertyDefinition-date-attribute',
    __typename: 'CustomPropertyDefinition',
    name: 'A date attribute',
    propertyType: 'TYPE_DATE',
    typeDefinition: null,
    usages: [],
  },
};

const enumAttribute: QueryType['rawResponse'] = {
  customAttribute: {
    id: 'CustomPropertyDefinition-enum-attribute',
    __typename: 'CustomPropertyDefinition',
    name: 'Favorite food',
    propertyType: 'TYPE_ENUM',
    typeDefinition: {
      __typename: 'EnumeratedPropertyDefinition',
      enumeratedValues: [
        { isDeleted: false, value: 'Taco', valueId: { id: '123' } },
        {
          isDeleted: false,
          value: 'Burrito',
          valueId: { id: '234' },
        },
        {
          isDeleted: false,
          value: 'Quesadilla',
          valueId: { id: '456' },
        },
        {
          isDeleted: true,
          value: 'Spaghetti',
          valueId: { id: '678' },
        },
      ],
    },
    usages: [],
  },
};

const numberAttribute: QueryType['rawResponse'] = {
  customAttribute: {
    id: 'CustomPropertyDefinition-number-attribute',
    __typename: 'CustomPropertyDefinition',
    name: 'My cool number attribute',
    propertyType: 'TYPE_NUMBER',
    typeDefinition: null,
    usages: [],
  },
};

const stringAttribute: QueryType['rawResponse'] = {
  customAttribute: {
    id: 'CustomPropertyDefinition-string-attribute',
    __typename: 'CustomPropertyDefinition',
    name: 'My string attribute',
    propertyType: 'TYPE_STRING',
    typeDefinition: null,
    usages: [],
  },
};

const hydratedAttributes = mockCustomAttributes.map(({ node }): QueryType['rawResponse'] => {
  const { id, __typename, name, propertyType } = node;

  return {
    customAttribute: {
      id,
      __typename,
      name,
      propertyType,
      typeDefinition:
        propertyType !== 'TYPE_ENUM'
          ? null
          : {
              __typename: 'EnumeratedPropertyDefinition',
              enumeratedValues: [
                { isDeleted: false, value: `Option A ${name}`, valueId: { id: '123' } },
                { isDeleted: false, value: `Option B ${name}`, valueId: { id: '234' } },
                { isDeleted: false, value: `Option C ${name}`, valueId: { id: '456' } },
                { isDeleted: false, value: `Option D ${name}`, valueId: { id: '678' } },
                { isDeleted: true, value: `Option deleted ${name}`, valueId: { id: '789' } },
              ],
            },
    },
  };
});

const attributes = [
  booleanAttribute,
  dateAttribute,
  enumAttribute,
  numberAttribute,
  stringAttribute,
  ...hydratedAttributes,
];

export const ExistingCustomAttributeMockDecorator = MockDataOverrideDecorator<QueryType>(
  Query,
  ({ variables }, { args }: { args: CustomAttributeUsageArgs }) => {
    const { id } = variables;
    const { hasAttributeUsages } = args;

    const foundAttribute = attributes.find(
      ({ customAttribute }) =>
        customAttribute?.__typename === 'CustomPropertyDefinition' && customAttribute.id === id
    );

    if (!foundAttribute) return { customAttribute: null };

    return {
      customAttribute: {
        ...foundAttribute.customAttribute,
        usages: hasAttributeUsages ? [{ id: 'segment-1' }, { id: 'segment-2' }] : [],
      },
    };
  }
);
