import React, { FC } from 'react';

import { Box } from '@attentive/picnic';

import { BlankSegment, Segment } from '../../constants';

import { ExpressionDetail } from './components/ExpressionDetail';
import { OperatorLabel } from './components/OperatorLabel';

interface SegmentExpressionDetailsProps {
  companyTimezone: string;
  segment: Segment | BlankSegment;
}

export const NewSegmentExpressionDetails: FC<SegmentExpressionDetailsProps> = ({
  companyTimezone,
  segment,
}) => {
  const segmentOperator = segment.operator;

  return (
    <Box css={{ maxWidth: '600px' }}>
      <OperatorLabel operator={segmentOperator} css={{ mb: '$space4' }} />
      {segment.expressions.map((expression, idx) => {
        const key = `exp-${expression.id}`;
        return (
          <ExpressionDetail
            companyTimezone={companyTimezone}
            expression={expression}
            key={key}
            segmentOperator={segmentOperator}
            css={{ pt: idx === 0 ? '$space0' : '$space6' }}
          />
        );
      })}
    </Box>
  );
};
