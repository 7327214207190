import React, { FC } from 'react';

import { Box, PicnicCss, styled } from '@attentive/picnic';

import { IsVerbType, OperatorType, SegmentComponent, SegmentExpression } from '../../../constants';
import { isNewLocationFilterType } from '../../../utils/typeAssertions';
import { getDateReplacements } from '../getDateReplacements';

import BulkPostalCodeDescription from './BulkPostalCodeDescription';
import { OperatorLabel } from './OperatorLabel';
import TextWithLinks from './TextWithLinks';

const IndentedBox = styled(Box, {
  ml: '$space5',
  pl: '$space5',
  borderLeft: '1px solid $grayscale300',
});

type ExpressionItemProps = {
  component: SegmentComponent;
  companyTimezone: string;
  hasSiblings?: boolean;
  operator: OperatorType;
};

const ExpressionItem: FC<ExpressionItemProps> = ({
  component,
  companyTimezone,
  hasSiblings,
  operator,
}) => {
  const componentWords = component.description?.split(' ');
  const dateReplacements = getDateReplacements(component.parameters, companyTimezone);
  const isBulkPostalCode = isNewLocationFilterType(component.parameters.subscriberProperty);

  if (!componentWords) return null;

  const content = isBulkPostalCode ? (
    <BulkPostalCodeDescription
      description={component.description}
      values={component.parameters.locations}
      isVerb={component.parameters.isVerb as IsVerbType}
      operator={operator}
    />
  ) : (
    <TextWithLinks words={componentWords} stringReplacements={dateReplacements} />
  );

  const ContentWrapper = hasSiblings ? IndentedBox : Box;

  return <ContentWrapper css={{ mt: '$space2' }}>{content}</ContentWrapper>;
};

type ExpressionDetailProps = {
  companyTimezone: string;
  css?: PicnicCss;
  expression: SegmentExpression;
  segmentOperator: OperatorType;
};

export const ExpressionDetail: FC<ExpressionDetailProps> = ({
  companyTimezone,
  css,
  expression,
  segmentOperator,
}) => {
  const hasMultipleExpressions = expression.segmentComponents.length > 1;

  return (
    <IndentedBox css={css}>
      {hasMultipleExpressions && <OperatorLabel operator={expression.operator} />}
      {expression.segmentComponents.map((item) => {
        const key = `exp-${expression.id}-comp-${item.id}`;

        return (
          <ExpressionItem
            key={key}
            component={item}
            hasSiblings={hasMultipleExpressions}
            companyTimezone={companyTimezone}
            operator={hasMultipleExpressions ? expression.operator : segmentOperator}
          />
        );
      })}
    </IndentedBox>
  );
};
