import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

export interface GetSegmentOptionsHeaders {
  getProfileSegmentOptionsDelay?: number;
  getProfileSegmentOptionsResponse?: { keys: string[]; nextToken: boolean | null };
  getProfileSegmentOptionsStatus?: number;
  getProfileSegmentOptionsErrorMessage?: string;
}

export const getProfileSegmentOptions = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options/profile',
  (req, res, ctx) => {
    const queryParams = req.url.search;
    const isAttributeSet = queryParams.includes('attributeSet');
    const {
      getProfileSegmentOptionsDelay = 0,
      getProfileSegmentOptionsResponse = { keys: ['pie', 'tacos', 'burgers'], nextToken: null },
      getProfileSegmentOptionsStatus = 200,
      getProfileSegmentOptionsErrorMessage = 'Error message',
    }: GetSegmentOptionsHeaders = parseMSWHeaders(req.headers);

    const response = isAttributeSet
      ? { keys: null, nextToken: null }
      : getProfileSegmentOptionsResponse;

    if (getProfileSegmentOptionsStatus !== 200) {
      return res(
        ctx.status(getProfileSegmentOptionsStatus),
        ctx.json({ errors: [{ message: getProfileSegmentOptionsErrorMessage }] })
      );
    }

    return res(
      ctx.delay(getProfileSegmentOptionsDelay),
      ctx.status(getProfileSegmentOptionsStatus),
      ctx.json(response)
    );
  }
);
