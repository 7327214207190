import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { SegmentOptions } from '../../constants/interfaces';
import { segmentOptionsMock } from '../data/segmentOptions';

interface GetSegmentOptionsHeaders {
  getSegmentOptionsDelay?: number;
  getSegmentOptionsResponse?: SegmentOptions;
  getSegmentOptionsStatus?: number;
}

export const getSegmentOptions = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options',
  (req, res, ctx) => {
    const {
      getSegmentOptionsDelay = 0,
      getSegmentOptionsResponse = segmentOptionsMock,
      getSegmentOptionsStatus = 200,
    }: GetSegmentOptionsHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getSegmentOptionsDelay),
      ctx.status(getSegmentOptionsStatus),
      ctx.json(getSegmentOptionsResponse)
    );
  }
);
