import { faker } from '@faker-js/faker';

import { PAGE_SIZE } from '../../components/ProductDataDialog/hooks';

export const mockResponsePage0 = {
  values: [
    {
      displayName: 'Celeste Dress',
      optionValue: 'celeste-dress',
      graphId: 'graphid-1',
    },
    {
      displayName: 'Cosmos Dress',
      optionValue: 'cosmos-dress',
      graphId: 'graphid-2',
    },
    {
      displayName: 'Cosmos Dress 10.00% Off Auto renew',
      optionValue: 'Cosmos Dress 10.00% Off Auto renew',
      graphId: 'graphid',
    },
    {
      displayName: 'KR Test',
      optionValue: 'KR Test',
      graphId: 'graphid',
    },
    {
      displayName: 'Pockets Denim Dress',
      optionValue: 'Pockets Denim Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Pockets Denim Dress 2',
      optionValue: 'Pockets Denim Dress 2',
      graphId: 'graphid',
    },
    {
      displayName: 'Pockets Denim Dress updated',
      optionValue: 'Pockets Denim Dress updated',
      graphId: 'graphid',
    },
    {
      displayName: 'Pockets Dress',
      optionValue: 'Pockets Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Pom Dress',
      optionValue: 'Pom Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Pom Dress 10.00% Off Auto renew',
      optionValue: 'Pom Dress 10.00% Off Auto renew',
      graphId: 'graphid',
    },
    {
      displayName: 'Sakura Dress',
      optionValue: 'Sakura Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Sakura Dress 10.00% Off Auto renew',
      optionValue: 'Sakura Dress 10.00% Off Auto renew',
      graphId: 'graphid',
    },
    {
      displayName: 'Selene Dress',
      optionValue: 'Selene Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Selene Dress 10.00% Off Auto renew',
      optionValue: 'Selene Dress 10.00% Off Auto renew',
      graphId: 'graphid',
    },
    {
      displayName: 'Sunnie Dress',
      optionValue: 'Sunnie Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Sunnie Dress 10.00% Off Auto renew',
      optionValue: 'Sunnie Dress 10.00% Off Auto renew',
      graphId: 'graphid',
    },
    {
      displayName: 'T shirt',
      optionValue: 'T shirt',
      graphId: 'graphid',
    },
    {
      displayName: 'Unpublished Dress',
      optionValue: 'Unpublished Dress',
      graphId: 'graphid',
    },
    {
      displayName: 'Another shirt I am sure',
      optionValue: 'Another shirt I am sure',
      graphId: 'graphid',
    },
    {
      displayName: 'Cloudy Dress',
      optionValue: 'Cloudy Dress',
      graphId: 'graphid',
    },
  ],
  totalResults: 26,
};

export const mockResponsePage1 = {
  values: [
    {
      displayName: 'Cloudy Dress MkII',
      optionValue: 'Cloudy Dress MkII',
      graphId: 'graphid',
    },
    {
      displayName: 'Cloudy Dress MkIII',
      optionValue: 'Cloudy Dress MkIII',
      graphId: 'graphid',
    },
    {
      displayName: 'The Actual Ironman Suit',
      optionValue: 'The Actual Ironman Suit',
      graphId: 'graphid',
    },
    {
      displayName: 'A Potato Sack',
      optionValue: 'A Potato Sack',
      graphId: 'graphid',
    },
    {
      displayName: 'Not sure, just buy',
      optionValue: 'Not sure, just buy',
      graphId: 'graphid',
    },
    {
      displayName: 'JNCO Jeans',
      optionValue: 'JNCO Jeans',
      graphId: 'graphid',
    },
  ],
  totalResults: 26,
};

export const dressValues = [
  {
    displayName: 'Cloudy Dress MkIII',
    optionValue: 'Cloudy Dress MkIII',
    graphId: 'graphid',
  },
  {
    displayName: 'Celeste Dress',
    optionValue: 'celeste-dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Cosmos Dress',
    optionValue: 'cosmos-dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Cosmos Dress 10.00% Off Auto renew',
    optionValue: 'Cosmos Dress 10.00% Off Auto renew',
    graphId: 'graphid',
  },
  {
    displayName: 'Cloudy Dress MkII',
    optionValue: 'Cloudy Dress MkII',
    graphId: 'graphid',
  },
  {
    displayName: 'Pockets Denim Dress',
    optionValue: 'Pockets Denim Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Pockets Denim Dress 2',
    optionValue: 'Pockets Denim Dress 2',
    graphId: 'graphid',
  },
  {
    displayName: 'Pockets Denim Dress updated',
    optionValue: 'Pockets Denim Dress updated',
    graphId: 'graphid',
  },
  {
    displayName: 'Pockets Dress',
    optionValue: 'Pockets Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Pom Dress',
    optionValue: 'Pom Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Pom Dress 10.00% Off Auto renew',
    optionValue: 'Pom Dress 10.00% Off Auto renew',
    graphId: 'graphid',
  },
  {
    displayName: 'Sakura Dress',
    optionValue: 'Sakura Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Sakura Dress 10.00% Off Auto renew',
    optionValue: 'Sakura Dress 10.00% Off Auto renew',
    graphId: 'graphid',
  },
  {
    displayName: 'Selene Dress',
    optionValue: 'Selene Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Selene Dress 10.00% Off Auto renew',
    optionValue: 'Selene Dress 10.00% Off Auto renew',
    graphId: 'graphid',
  },
  {
    displayName: 'Sunnie Dress',
    optionValue: 'Sunnie Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Sunnie Dress 10.00% Off Auto renew',
    optionValue: 'Sunnie Dress 10.00% Off Auto renew',
    graphId: 'graphid',
  },
  {
    displayName: 'August Dress',
    optionValue: 'August Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Galaxy Dress',
    optionValue: 'Galaxy Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'Another Dress',
    optionValue: 'Another Dress',
    graphId: 'graphid',
  },
  {
    displayName: 'This is the final dress',
    optionValue: 'This is the final dress',
    graphId: 'graphid',
  },
];

export const getPaginatedDressResponse = (offset: number) => {
  return {
    values: dressValues.slice(offset, offset + PAGE_SIZE),
    totalResults: dressValues.length,
  };
};

const getFakeProduct = (idx: number) => ({
  displayName: `${faker.commerce.productName()} ${idx}`,
  optionValue: `${faker.random.words(3)} ${idx}`,
  graphId: 'foo',
});

export const getPaginatedFakeResponse = (totalResults = 1000) => {
  return {
    values: Array.from({ length: PAGE_SIZE }, (_, idx) => getFakeProduct(idx)),
    totalResults,
  };
};

export const mockEmptyResponse = { values: [], totalResults: 0 };
