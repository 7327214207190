import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { SegmentSubscriberCount, SegmentSubscriberCountV2 } from '../../constants';
import {
  currentSegmentCountResponse,
  currentSegmentCountResponseV2,
} from '../data/currentSegmentCount';

interface GetCurrentSegmentCountHeaders {
  getCurrentSegmentCountDelay?: number;
  getCurrentSegmentCountResponse?: SegmentSubscriberCount;
  getCurrentSegmentCountStatus?: number;
}

export const getCurrentSegmentCount = mswRest.post(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-count',
  (req, res, ctx) => {
    const {
      getCurrentSegmentCountDelay = 0,
      getCurrentSegmentCountResponse = currentSegmentCountResponse,
      getCurrentSegmentCountStatus = 200,
    }: GetCurrentSegmentCountHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getCurrentSegmentCountDelay),
      ctx.status(getCurrentSegmentCountStatus),
      ctx.json(getCurrentSegmentCountResponse)
    );
  }
);

export interface GetCurrentSegmentCountV2Headers {
  getCurrentSegmentCountV2Delay?: number;
  getCurrentSegmentCountV2Response?: SegmentSubscriberCountV2;
  getCurrentSegmentCountV2Status?: number;
}

export const getCurrentSegmentCountV2 = mswRest.post(
  'https://ui-api-devel.attentivemobile.com/user-profile/v2/segment-count',
  (req, res, ctx) => {
    const {
      getCurrentSegmentCountV2Delay = 0,
      getCurrentSegmentCountV2Response = currentSegmentCountResponseV2,
      getCurrentSegmentCountV2Status = 200,
    }: GetCurrentSegmentCountV2Headers = parseMSWHeaders(req.headers);

    // When a request times out, nginx returns an HTML page instead of a JSON response
    if (getCurrentSegmentCountV2Status === 504) {
      return res(
        ctx.delay(getCurrentSegmentCountV2Delay),
        ctx.status(getCurrentSegmentCountV2Status),
        ctx.text(`<html>
            <head><title>504 Gateway Time-out</title></head>
            <body>
            <center><h1>504 Gateway Time-out</h1></center>
            <hr><center>nginx/1.18.0 (Ubuntu)</center>
            </body>
            </html>
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            <!-- a padding to disable MSIE and Chrome friendly error page -->
            `)
      );
    }

    return res(
      ctx.delay(getCurrentSegmentCountV2Delay),
      ctx.status(getCurrentSegmentCountV2Status),
      ctx.json(getCurrentSegmentCountV2Response)
    );
  }
);
