import React from 'react';

import { Box } from '@attentive/picnic';

import { BlankSegment, IsVerbType, Segment } from '../../constants';

import BulkPostalCodeDescription from './components/BulkPostalCodeDescription';
import TextWithLinks from './components/TextWithLinks';
import { getDateReplacements } from './getDateReplacements';

export interface LegacySegmentExpressionDetailsProps {
  companyTimezone: string;
  segment: Segment | BlankSegment;
}

export const LegacySegmentExpressionDetails: React.FC<LegacySegmentExpressionDetailsProps> = ({
  companyTimezone,
  segment,
}) => (
  <Box css={{ maxWidth: '600px' }}>
    {segment.expressions.map((expression, expressionIdx) => {
      const expressionKey = `exp-${expression.id}-${expressionIdx}`;
      return (
        <Box
          key={expressionKey}
          css={{ borderLeft: '1px solid $borderDefault', p: '$space2', mt: '$space4' }}
        >
          {expressionIdx > 0 ? (
            <b data-testid="operator">{segment.operator.toLowerCase()}&nbsp;</b>
          ) : null}
          {expression.segmentComponents.map((component, componentIdx) => {
            const componentKey = `comp-${component.id}-${componentIdx}`;
            const componentWords = component.description?.split(' ');
            const dateReplacements = getDateReplacements(component.parameters, companyTimezone);
            const isBulkPostalCode =
              component.parameters.subscriberProperty === 'BULK_POSTAL_CODES';

            return (
              <React.Fragment key={componentKey}>
                {componentIdx > 0 ? (
                  <b data-testid="operator">{expression.operator.toLowerCase()}&nbsp;</b>
                ) : null}
                {componentWords && !isBulkPostalCode && (
                  <TextWithLinks words={componentWords} stringReplacements={dateReplacements} />
                )}
                {componentWords && isBulkPostalCode && (
                  <BulkPostalCodeDescription
                    description={component.description}
                    values={component.parameters.locations}
                    isVerb={component.parameters.isVerb as IsVerbType}
                    operator={componentIdx > 0 ? expression.operator : segment.operator}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      );
    })}
  </Box>
);
