import { AvailableDataType } from '../../constants';

export const availableSegmentDataMock: Array<{ type: AvailableDataType; active: boolean }> = [
  { type: AvailableDataType.PURCHASE, active: true },
  { type: AvailableDataType.PRODUCT_VIEW, active: true },
  { type: AvailableDataType.ADD_TO_CART, active: true },
];

export const availableSegmentDataMockMap = new Map(
  availableSegmentDataMock.map(({ type, active }) => [type, active])
);
