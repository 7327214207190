import { KlaviyoCampaign } from '../../components';

export const mockKlaviyoCampaignsResponse: KlaviyoCampaign[] = [
  {
    name: 'Winter Campaign',
    externalId: 'abc123',
  },
  {
    name: 'Spring Campaign',
    externalId: 'def456',
  },
  {
    name: 'Summer Campaign',
    externalId: 'ghi789',
  },
  {
    name: 'Fall Campaign',
    externalId: 'jkl012',
  },
];
