import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { SegmentableAttributes } from '../../constants/interfaces';
import { segmentableAttributesMock } from '../data/segmentableAttributes';

interface GetSegmentableAttributesHeaders {
  getSegmentableAttributesDelay?: number;
  getSegmentableAttributesResponse?: { segmentableAttributesByVendor: SegmentableAttributes };
  getSegmentableAttributesStatus?: number;
}

export const getSegmentableAttributes = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options/segmentable-attributes',
  (req, res, ctx) => {
    const {
      getSegmentableAttributesDelay = 0,
      getSegmentableAttributesResponse = segmentableAttributesMock,
      getSegmentableAttributesStatus = 200,
    }: GetSegmentableAttributesHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getSegmentableAttributesDelay),
      ctx.status(getSegmentableAttributesStatus),
      ctx.json(getSegmentableAttributesResponse)
    );
  }
);
