import _useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment from "./__generated__/useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import Fragment from './__generated__/useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment.graphql';
_useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment;
export const useGetSelectedItem = fragmentRef => {
  const {
    internalIdResponse,
    externalIdResponse
  } = useFragment(Fragment, fragmentRef);
  const internalIdCustomPropertyDefinition = internalIdResponse === null || internalIdResponse === void 0 ? void 0 : internalIdResponse.customPropertyDefinitionByInternalId;

  if (internalIdCustomPropertyDefinition !== null && internalIdCustomPropertyDefinition !== void 0 && internalIdCustomPropertyDefinition.id) {
    return {
      node: internalIdCustomPropertyDefinition
    };
  } else if (externalIdResponse !== null && externalIdResponse !== void 0 && externalIdResponse.id) {
    return {
      node: externalIdResponse
    };
  }

  return null;
};