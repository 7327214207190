import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import { AvailableDataType } from '../../constants';
import { availableSegmentDataMock } from '../data/segmentOptionAvailabilities';

export interface GetSegmentOptionAvailabilitiesHeaders {
  getSegmentOptionAvailabilitiesDelay?: number;
  getSegmentOptionAvailabilitiesResponse?: Array<{ type: AvailableDataType; active: boolean }>;
  getSegmentOptionAvailabilitiesStatus?: number;
}

export const getSegmentOptionAvailabilities = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segment-options/segment-option-availabilities',
  (req, res, ctx) => {
    const {
      getSegmentOptionAvailabilitiesDelay = 0,
      getSegmentOptionAvailabilitiesResponse = availableSegmentDataMock,
      getSegmentOptionAvailabilitiesStatus = 200,
    }: GetSegmentOptionAvailabilitiesHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(getSegmentOptionAvailabilitiesDelay),
      ctx.status(getSegmentOptionAvailabilitiesStatus),
      ctx.json(getSegmentOptionAvailabilitiesResponse)
    );
  }
);
