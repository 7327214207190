import React, { FC } from 'react';

import { useIsOperatorUIEnabled } from '../OperatorUIToggle';

import {
  LegacySegmentExpressionDetails,
  LegacySegmentExpressionDetailsProps,
} from './LegacySegmentExpressionDetails';
import { NewSegmentExpressionDetails } from './NewSegmentExpressionDetails';

export const SegmentExpressionDetails: FC<LegacySegmentExpressionDetailsProps> = (props) => {
  const enableOperatorUI = useIsOperatorUIEnabled();

  if (enableOperatorUI) {
    return <NewSegmentExpressionDetails {...props} />;
  }

  return <LegacySegmentExpressionDetails {...props} />;
};
