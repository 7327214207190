/**
 * @generated SignedSource<<c3c8a32472ad2b136903a1a5d3ced692>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeSelectTargetingCommon_fragment$data = {
  readonly company: {
    readonly id?: string;
    readonly userPropertyDefinitionsV2?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly customPropertyId?: {
            readonly id: string;
          };
          readonly id?: string;
          readonly name?: string;
        };
      }>;
    } | null;
  };
  readonly " $fragmentType": "CustomAttributeSelectTargetingCommon_fragment";
};
export type CustomAttributeSelectTargetingCommon_fragment$key = {
  readonly " $data"?: CustomAttributeSelectTargetingCommon_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeSelectTargetingCommon_fragment">;
};

import CustomAttributeSelectTargetingCommon_Query_refetchable_graphql from './CustomAttributeSelectTargetingCommon_Query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "company",
  "userPropertyDefinitionsV2"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": CustomAttributeSelectTargetingCommon_Query_refetchable_graphql
    }
  },
  "name": "CustomAttributeSelectTargetingCommon_fragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": "userPropertyDefinitionsV2",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "includeDeleted": false,
                      "source": "USER_PROPERTY_SOURCE_CUSTOM"
                    }
                  }
                ],
                "concreteType": "UserPropertyDefinitionConnectionV2",
                "kind": "LinkedField",
                "name": "__CustomAttributeSelectTargetingCommon_userPropertyDefinitionsV2_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionEdgeV2",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomPropertyID",
                                "kind": "LinkedField",
                                "name": "customPropertyId",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CustomPropertyDefinition",
                            "abstractKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "__CustomAttributeSelectTargetingCommon_userPropertyDefinitionsV2_connection(filter:{\"includeDeleted\":false,\"source\":\"USER_PROPERTY_SOURCE_CUSTOM\"})"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f4f6ceec995bff85395bdd068a8fb7ca";

export default node;
