import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

interface PostSegmentHeaders {
  postSegmentDelay?: number;
  postSegmentResponse?: object;
  postSegmentStatus?: number;
}

export const postSegment = mswRest.post(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segments',
  (req, res, ctx) => {
    const {
      postSegmentDelay = 0,
      postSegmentResponse = {},
      postSegmentStatus = 200,
    }: PostSegmentHeaders = parseMSWHeaders(req.headers);

    return res(
      ctx.delay(postSegmentDelay),
      ctx.status(postSegmentStatus),
      ctx.json(postSegmentResponse)
    );
  }
);
