import {
  IntegrationsResponse,
  KLAVIYO_VENDOR_NAME,
  KLAVIYO_EMAIL_FEATURE,
  KLAVIYO_SEGMENTS_FEATURE,
  Visibility,
  ThirdPartyIntegration,
  SHOPIFY_VENDOR_NAME,
  KLAVIYO_DISPLAY_NAME,
  SHOPIFY_DISPLAY_NAME,
} from '../../constants';

const KLAVIYO_ID = 1;
const KLAVIYO_EMAIL_FEATURE_ID = 888;
const KLAVIYO_SEGMENTS_FEATURE_ID = 999;
export const KLAVIYO_VENDOR_INTEGRATION_ID = 111;

const SHOPIFY_ID = 2;
export const SHOPIFY_VENDOR_INTEGRATION_ID = 222;

export const fetchIntegrationsMock: IntegrationsResponse = {
  externalVendorApis: [
    {
      externalVendorApiId: KLAVIYO_ID,
      vendor: KLAVIYO_VENDOR_NAME,
      displayName: 'Klaviyo',
      status: { enabled: true },
      visibility: Visibility.ALL,
      features: [
        { type: KLAVIYO_EMAIL_FEATURE, externalVendorFeatureId: KLAVIYO_EMAIL_FEATURE_ID },
        { type: KLAVIYO_SEGMENTS_FEATURE, externalVendorFeatureId: KLAVIYO_SEGMENTS_FEATURE_ID },
      ],
      companyVendorIntegrationId: KLAVIYO_VENDOR_INTEGRATION_ID,
    },
    {
      externalVendorApiId: 2,
      vendor: 'DISABLED_INTEGRATION',
      displayName: 'DISABLED_INTEGRATION',
      status: { enabled: false },
      visibility: Visibility.ALL,
      features: [],
      companyVendorIntegrationId: 999,
    },
  ],
};

export const fetchKlaviyoIntegrationDetailsMock: ThirdPartyIntegration = {
  externalVendorApiId: KLAVIYO_ID,
  vendor: KLAVIYO_VENDOR_NAME,
  displayName: KLAVIYO_DISPLAY_NAME,
  companyVendorIntegrationId: KLAVIYO_VENDOR_INTEGRATION_ID,
  features: [
    {
      externalVendorFeatureType: KLAVIYO_EMAIL_FEATURE,
      externalVendorFeatureId: KLAVIYO_EMAIL_FEATURE_ID,
      enabled: true,
    },
    {
      externalVendorFeatureType: KLAVIYO_SEGMENTS_FEATURE,
      externalVendorFeatureId: KLAVIYO_SEGMENTS_FEATURE_ID,
      enabled: true,
    },
  ],
};

export const fetchShopifyIntegrationDetailsMock: ThirdPartyIntegration = {
  externalVendorApiId: SHOPIFY_ID,
  vendor: SHOPIFY_VENDOR_NAME,
  displayName: SHOPIFY_DISPLAY_NAME,
  companyVendorIntegrationId: SHOPIFY_VENDOR_INTEGRATION_ID,
  features: [],
};
