// This payload contains three unique journeys and five revisions
// journey 1 has 1 revision
// journey 2 has 2 revisions but its newest revision appears first in the array
// journey 3 has 2 revisions with the newest reivision appearing last in the array
export const journeysUsingSegment = [
  {
    journeyId: '1',
    revisionId: '1',
    name: 'Birthday journey v1',
    url: '/journeys/1/revision/1/edit',
    journeyActive: true,
  },
  {
    journeyId: '2',
    revisionId: '5',
    name: 'New users journey v2',
    url: '/journeys/2/revision/5/view',
    journeyActive: true,
  },
  {
    journeyId: '2',
    revisionId: '2',
    name: 'New users journey v1',
    url: '/journeys/2/revision/2/view',
    journeyActive: true,
  },
  {
    journeyId: '3',
    revisionId: '3',
    name: 'MVP users v1',
    url: '/journeys/3/revision/3/view',
    journeyActive: true,
  },
  {
    journeyId: '3',
    revisionId: '4',
    name: 'MVP users v2',
    url: '/journeys/3/revision/4/edit',
    journeyActive: true,
  },
];
